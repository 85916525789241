import React, { Component } from 'react';
import styled from 'styled-components';
import Flexbox from 'flexbox-react';
import { COLORS } from '../../constants/colors';
import { NavLink as Nav, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ls from 'local-storage';
import * as S from '../../elements/styled/Elements';

import { setAppType, updateUser } from '../../actions/auth';
import { getUserInfo } from '../../actions/user';
import { getToken } from '../../selectors/auth';
import { getIsFetching } from '../../selectors/components';
import { isLoading, getUserData } from '../../selectors/user';
import { RButton, RGroup } from '../../elements/styled/FormPageStyles';
import { USER_ROLE } from '../../constants/testData';
import Logo from '../../elements/logo';
import DropdownMenu from '../../elements/submenu';


const Wrapper = styled.div`
	background:${COLORS.header};
	padding: 25px 30px;
	width: 100%;
	height: 80px;
  position: fixed;
  top: 0;
  z-index: 100;
`;
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  max-width: 960px;
  margin: 0 auto;
`;
const Menu = styled.ul`
	display: flex;
	align-items: center;
`;
const MenuItem = styled.li`
	display: inline-block;
	text-align: center;
	padding-left: 50px;
  &:last-child{
    padding-right: 30px;
  }
`;
const NavLink = styled(Nav)`
		text-decoration: none;
		color: ${COLORS.secondary};
		font-size: 16px;
    line-height: 18px;
    position: relative;
    display: block;
    &:before{
			content:'';
			position:absolute;
			left:50%;
			bottom:-5px;
			right:50%;
			width:0;
			border-bottom:3px solid ${COLORS.secondary};
			transition:all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
		}
		&.active{
			color: ${COLORS.secondary};
			text-shadow:0 0 1px ${COLORS.secondary};
			&:before{
				width:100%;
				left:0;
			}
		}
		&:not(.active):hover{
			color: ${COLORS.secondary};
			text-shadow:0 0 1px ${COLORS.secondary};
			&:before{
				width:100%;
				left:0;
			}
		}
`;
const RG = styled(RGroup)`
  display: flex;
  align-items: center;
  .ant-radio-button-wrapper-checked{
     box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20);
  }
  .ant-radio-button-wrapper:first-child{
      border-radius: 30px;
      border-left:  1px solid ${COLORS.grey};
  }
   .ant-radio-button-wrapper:first-child:hover{
    border-radius: 30px;
    border-left: 1px solid #6B40C3;
  }
  .ant-radio-button-wrapper-checked:first-child{
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20) !important;
  }
  .ant-radio-button-wrapper-checked:first-child:hover{
      border-left: none;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20) !important;
  }
  .ant-radio-button-wrapper-checked:hover {
     box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20);
  }
  .ant-radio-button-wrapper:last-child{
     border-radius: 30px;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background: ${COLORS.pink};
    color: ${COLORS.secondary};
    border: 1px solid ${COLORS.pink};
    border-left: none;
  }
  .ant-radio-button-wrapper:not(:first-child)::before{
    border: none;
    background-color: transparent !important;
  }

  .ant-radio-button-wrapper:not(:first-child)::before{
    background:none;
    border: 1px solid ${COLORS.pink};
    border-left: none;
  }
  .ant-radio-button-wrapper-checked::before{
    background-color: transparent;
  }
  .ant-radio-button-wrapper:not(:first-child)::before{
    border: none;
  }
  .ant-radio-button-wrapper-checked:focus-within {
    outline: none;
    border-left: none;
  }
  .ant-radio-button-wrapper-checked:focus {
    outline: none;
    border-left: none;
  }
 `;
const RB = styled(RButton)`
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin: 0;
  line-height: 20px;
  margin-right: 10px;
  text-align: center;
  background: ${COLORS.secondary};
  color: ${COLORS.primary};
  border: 1px solid ${COLORS.grey};
  max-width: 100%;
	cursor: pointer;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  .ant-radio-button-checked{
    background: ${COLORS.pink};
    color: ${COLORS.secondary};
    border-color: ${COLORS.pink};
  }
  &:hover{
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20);
    border-color: ${COLORS.violet};
  }
`;
const menuItems = ['All Items', 'Found Members', 'Business Partners', 'Public Users'];

const textConvertToLinks = (text) => {
  return text.toLowerCase().split(' ').join('-');
};

class AppHeader extends Component {
  state = {
    defaultCategory: 'Public',
    appType: 'public',
    path: '',
    isActive: false,
  };
  
  componentDidMount() {
    const { setAppType, updateUser, location: { pathname }, getUserInfo } = this.props;
    const { appType } = this.state;
    const token = ls.get('ACCESS_TOKEN');
    const userUid = ls.get('CURRENT_USER_UID');
    if (token) {
      getUserInfo({ uid: userUid });
    }
    let path = `${pathname.split('/')[pathname.split('/').length - 1]}`;
    if (path === 'business-partners' || path === 'public-users' || path === 'found-members' || path === 'business-members') {
      this.setState({ isActive: true, path });
    }
    if (path === 'business' || path === 'edit-hours' || path === 'business-details') {
      this.setState({ appType: 'business' });
    }
    if (path) {
      this.setState({
        path
      });
      updateUser(token, userUid);
      return null;
    }
    setAppType(appType);
  }
  
  componentWillReceiveProps(nextProps) {
    const { location: { pathname }, setAppType } = nextProps;
    const { appType } = this.state;
    const path = pathname.split('/');
    const index = path.findIndex(isLoading => isLoading === 'business-partners' || isLoading === 'public-users' || isLoading === 'found-members' || isLoading === 'business-members');
    if (index) {
      this.setState({ isActive: true, path: path[index] });
    } else {
      this.setState({ path: '', isActive: false });
    }
    setAppType(appType);
  }
  
  onHandleChangeAppType = (e) => {
    const { setAppType, history, location: { pathname } } = this.props;
    const appType = e.target.value;
    this.setState({ appType });
    setAppType(appType);
    let path = pathname.split('/');
    path.pop();
    let location = path.join('/');
    if (location) {
      history.push(`${location}/${appType}`);
    }
  };
  
  render() {
    const { user } = this.props;
    const { appType, isActive, path } = this.state;
    const emailAddress = user && user.emailAddress;
    const role = ls.get('ROLE');
    const userUid = ls.get('CURRENT_USER_UID');
    const auth = ls.get('ACCESS_TOKEN');
    const appTypeToggle = auth
      ? null
      : (<RG
        value={appType}
        onChange={this.onHandleChangeAppType}
      >
        <RB value="public">Public</RB>
        <RB value="business">Business</RB>
      </RG>);
    const dropDownMenu = auth ? <DropdownMenu email={emailAddress} role={role}/> : null;
    const menu = auth && role === USER_ROLE['superAdmin'] ? menuItems.map((text, i) => {
        const addActiveClass = textConvertToLinks(text) === path;
        return <MenuItem key={i}>
          <NavLink
            exact
            to={`/${textConvertToLinks(text)}`}
            className={isActive && addActiveClass ? 'active' : ''}
          >
            {text}
          </NavLink>
        </MenuItem>;
      }
    ) : null;
    
    return (
      <Wrapper>
        <Header>
          <Flexbox justifyContent="space-between" width="100%">
            <Logo userUid={userUid}/>
            <Menu>
              {menu}
            </Menu>
            {appTypeToggle}
          </Flexbox>
          {dropDownMenu}
        </Header>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: getToken(state),
    isLoading: isLoading(state),
    user: getUserData(state),
    isFetching: getIsFetching(state)
  };
};

const mapDispatchToProps = (dispatch, ...props) => {
  return {
    setAppType: (appType) => setAppType(appType)(dispatch),
    updateUser: (token, userUid) => updateUser(token, userUid)(dispatch),
    getUserInfo: (data) => getUserInfo(data)(dispatch),
    // getCurrentUser: (userUid) => getCurrentUser(userUid)(dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader));


// const menu = role === 'FOUND_STAFF_MANAGEMENT' ? menuItemsAdmin.map((p, i) =>
//   <MenuItem key={i}>
//     <NavLink
//       exact
//       to={`/${textConvertToLinks(p)}`}
//       className={isActive && textConvertToLinks(p) === path ? 'active' : ''}>{p}</NavLink>
//   </MenuItem>) : menuItemsUser.map((p, i) =>
//   <MenuItem key={i}>
//     <NavLink
//       exact
//       to={`/${textConvertToLinks(p)}`}
//       className={isActive && textConvertToLinks(p) === path ? 'active' : ''}>{p}</NavLink>
//   </MenuItem>);
//