import { put, takeEvery, call } from 'redux-saga/effects';
import { Auth, User, Business, Components } from './../constants/actions';
import * as Api from '../api/index';
import fetchWrapper from './fetchWrapper';
import { push } from 'react-router-redux';

function* logIn({ payload }) {
  const response = yield fetchWrapper(call(Api.logIn, payload));
  if (response) {
    const token = response.token.value;
    const userUid = response.userUUID;
    if (userUid) {
      let currentUser = yield fetchWrapper(call(Api.getUserInfo, { uid: userUid }));
      if (currentUser) {
        yield put({ type: Auth.SET_CURRENT_USER_SAGA, payload: currentUser });
        yield put({ type: User.GET_USER_INFO_SAGA, payload: currentUser });
        yield put({ type: Auth.LOG_IN_SAGA, payload: { token, userUid } });
      }
    }
  }
}

function* signUp({ payload }) {
  const response = yield fetchWrapper(call(Api.signUp, payload));
  const { appType } = payload;
  if (response){
    yield put(push('/'));
    yield put({ type: Business.GET_LOCATION_LIST_SAGA, payload: [] });
    return null;
  }
  yield put(push(`/signup/${appType.toLowerCase()}`));
}

function* onLogInBySocials({ payload }) {
  yield put({ type: Components.FETCHING_PAYMENTS, payload: true });
  const response = yield fetchWrapper(call(Api.onLogInBySocials, payload));
  if (response) {
   
    const token = response.token.value;
    const userUid = response.userUUID;
    if (userUid) {
      let currentUser = yield fetchWrapper(call(Api.getUserInfo, { uid: userUid }));
      if (currentUser) {
        yield put({ type: Auth.SET_CURRENT_USER_SAGA, payload: currentUser });
        yield put({ type: User.GET_USER_INFO_SAGA, payload: currentUser });
        yield put({ type: Auth.LOG_IN_SAGA, payload: { token, userUid } });
      }
    }
    yield put({ type: Components.FETCHING_PAYMENTS, payload: false });
  }
  yield put({ type: Components.FETCHING_PAYMENTS, payload: false });
}

/*
* UNCOMMENT it if you need FACEBOOK and TWETTER SIGNUP
*
function* onSignUpBySocials({ payload }) {
  const response = yield fetchWrapper(call(Api.onSignUpBySocials, payload));
  const { appType } = payload;
  if (response){
    yield put(push('/'));
    yield put({ type: Business.GET_LOCATION_LIST_SAGA, payload: [] });
    return null;
  }
  yield put(push(`/signup/${appType.toLowerCase()}`));
}
*/

export default function* auth() {
  yield takeEvery(Auth.LOG_IN, logIn);
  yield takeEvery(Auth.SIGN_UP, signUp);
  yield takeEvery(Auth.ON_LOG_IN_BY_SOCIALS, onLogInBySocials);
/*
* UNCOMMENT it if you need FACEBOOK and TWETTER SIGNUP
* yield takeEvery(Auth.ON_SIGN_UP_BY_SOCIALS, onSignUpBySocials);
* */
};