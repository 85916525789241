import * as a from './../constants/actions';

export const logIn = (auth) => {
  return (dispatch) => dispatch({
    type: a.Auth.LOG_IN,
    payload: { ...auth }
  });
};

export const signUp = (auth) => {
  return (dispatch) => dispatch({
    type: a.Auth.SIGN_UP,
    payload: { ...auth }
  });
};

export const logOut = () => {
  return (dispatch) => dispatch({
    type: a.Auth.LOG_OUT
  });
};

export const setAppType = (appType) => {
  return (dispatch) => dispatch({
    type: a.Auth.SET_APP_TYPE,
    payload: appType
  });
};

export const updateUser = (token, userUid) => {
  return (dispatch) => dispatch({
    type: a.Auth.UPDATE_USER,
    payload: { token, userUid }
  });
};

export const onLogInBySocials = (auth) => {
  return (dispatch) => dispatch({
    type: a.Auth.ON_LOG_IN_BY_SOCIALS,
    payload: { ...auth }
  });
};

export const onSignUpBySocials = (auth) => {
  return (dispatch) => dispatch({
    type: a.Auth.ON_SIGN_UP_BY_SOCIALS,
    payload: { ...auth }
  });
};