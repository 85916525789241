import { put, takeEvery, call } from 'redux-saga/effects';
import { Payments, Components } from './../constants/actions';
import * as Api from '../api/index';
import fetchWrapper from './fetchWrapper';
import { onAddSuccessDelivery } from './deliveries';

function* onAddApprovedPayment({ payload }) {
  const { paymentId } = payload;
  yield put({ type: Components.FETCHING_PAYMENTS, payload: true });
  let paymentResponse = yield fetchWrapper(call(Api.onAddApprovedPayment, paymentId));
  if (paymentResponse){
    yield onAddSuccessDelivery({ payload });
    yield put({ type: Components.FETCHING_PAYMENTS, payload: false });
  }
  yield put({ type: Components.FETCHING_PAYMENTS, payload: false });
}
// function* onCreatePayment({ payload }) {
//   let foundItems = yield fetchWrapper(call(Api.onCreatePayment, payload));
// if (foundItems) {
//   yield put({ type: User.GET_USER_ITEMS_FOUND_SAGA, payload: foundItems });
// }
// }

export default function* user() {
  yield takeEvery(Payments.ADD_APPROVED_PAYMENT, onAddApprovedPayment);
  // yield takeEvery(Payments.ON_CREATE_PAYMENT, onCreatePayment);
};