import { put, takeEvery, call, fork } from 'redux-saga/effects';
import { Components, Admin } from './../constants/actions';
import { replace, push } from 'react-router-redux';
import * as Api from '../api/index';
import fetchWrapper from './fetchWrapper';

function* updateTableItems(response, actionType) {
  const { total: totalElements, items, page: currentPage } = response;
  for (let item of items) {
    yield fork(getUserImg, item, 'GET_ADMIN_IMG_SAGA');
  }
  yield put(replace({ search: `usersPage=${currentPage}`, params: currentPage }));
  yield put({ type: Admin[actionType], payload: { items, totalElements, currentPage } });
  yield put({ type: Components.CLEAR_SELECTED_ITEMS_SAGA, payload: true });
}

export function* getUserImg(user, actionType) {
  if (user && user.userProfilePicture) {
    const av = user.userProfilePicture && user.userProfilePicture.split('/');
    const imgUid = av[av.length - 1];
    const img = yield fetchWrapper(call(Api.getItemImage, { imgUid }), 'FETCHING_IMG');
    if (img) {
      yield put({ type: Admin[actionType], payload: { img, userUid: user.id } });
    }
  }
}

function* onDeleteAdminAccount({ payload }) {
  const { deletedUserUid, redirectTo } = payload;
  let response = yield fetchWrapper(call(Api.onDeleteAccount, { userUid: deletedUserUid }));
  if (response) {
    if (redirectTo) {
      yield put(push(redirectTo));
      return null;
    }
    yield getAdminUsers({ payload });
  }
}

function* getAdminUsers({ payload }) {
  let response = yield fetchWrapper(call(Api.getAdminUsers, payload));
  if (response) {
    yield updateTableItems(response, 'GET_ADMINS_LIST_SAGA');
  }
}

// function* getUsersBySearch({ payload }) {
//   let response = yield fetchWrapper(call(Api.getUsersBySearch, payload));
//   if (response) {
//     yield updateTableItems(response, 'GET_USERS_LIST_SAGA');
//   }
// }

export default function* admins() {
  // yield takeEvery(User.GET_USERS_BY_SEARCH, getUsersBySearch);
  // yield takeEvery(User.GET_USERS_LIST, getUsersList);
  yield takeEvery(Admin.GET_ADMINS_LIST, getAdminUsers);
  yield takeEvery(Admin.ON_DELETE_ADMIN_ACCOUNT, onDeleteAdminAccount);
};