import { fromJS } from 'immutable';
import { Admin, Auth } from './../constants/actions';

const initState = fromJS({
  admins: [],
  totalElements: null,
  currentPage: null,
});

const reducer = (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case Admin.ON_DELETE_ADMIN_ACCOUNT_SAGA: {
      if (payload || payload === null) {
        const { userUid } = payload;
        return state.set('admins', fromJS(state.get('admins').filter(el => el.id !== userUid)));
      }
      return state;
    }
    case Admin.GET_ADMIN_IMG_SAGA: {
      if (payload || payload === null) {
        const { userUid, img } = payload;
        const indexToUpdate = state.get('admins').findIndex(admin => admin.get('id') === userUid);
        return state.setIn(['admins', indexToUpdate, 'userProfilePicture'], fromJS(img));
      }
      return state;
    }
    case Auth.LOG_OUT: {
      return state.set('admins', fromJS([]))
        .set('totalElements', null)
        .set('currentPage', null);
    }
    case Admin.GET_ADMINS_LIST_SAGA: {
      if (payload || payload === null) {
        const { items: admins, totalElements, currentPage } = payload;
        return state.set('admins', fromJS(admins)).set('totalElements', fromJS(totalElements)).set('currentPage', fromJS(currentPage));
      }
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
