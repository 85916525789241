import { fromJS } from 'immutable';
import { User, Auth, Deliveries } from './../constants/actions';

const initState = fromJS({
  paymentId: null,
  packageSize: null,
  personalDetails: {},
  deliveryAddress: {},
  quotes: [],
  matchId: null
});

const reducer = (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case Deliveries.ADD_DELIVERY_OPTIONS: {
      if (payload || payload === null) {
        const { paymentId } = payload;
        return state.set('paymentId', fromJS(paymentId));
      }
      return state;
    }
    case Deliveries.ADD_ITEM_SIZE: {
      if (payload || payload === null) {
        const { packageSize } = payload;
        return state.set('packageSize', fromJS(packageSize));
      }
      return state;
    }

    case Deliveries.ADD_PERSONAL_DETAILS: {
      if (payload || payload === null) {
        return state.set('personalDetails', fromJS(payload));
      }
      return state;
    }
    case Deliveries.ADD_DELIVERY_DETAILS: {
      if (payload || payload === null) {
        return state.set('deliveryAddress', fromJS(payload));
      }
      return state;
    }
    case Deliveries.GET_DELIVERIES_QUOTES_SAGA: {
      if (payload || payload === null) {
        // console.log(payload);
        return state.set('quotes', fromJS(payload));
      }
      return state;
    }
    case Deliveries.ON_ADD_MATCH_ID: {
      if (payload || payload === null) {
        return state.set('matchId', fromJS(payload));
      }
      return state;
    }
    case Auth.LOG_OUT: {
      return state.set('payment', fromJS([]))
        // .set('totalElements', null)
        // .set('currentPage', null);
    }
    default:
      return state;
  }
};

export default reducer;
