import { fromJS } from 'immutable';
import { Business, Auth } from './../constants/actions';

const initState = fromJS({
  address: [],
  name: null,
  email: null,
  password: null,
  business: {}
});

const reducer = (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case Business.GET_PASS_AND_EMAIL: {
      if (payload || payload === null) {
        const { password, email, acceptMarketing } = payload;
        return state.set('password', fromJS(password)).set('email', fromJS(email)).set('acceptMarketing', fromJS(acceptMarketing));
      }
      return state;
    }
    case Business.GET_LOCATION_LIST_SAGA: {
      if (payload || payload === null) {
        return state.set('address', fromJS(payload));
      }
      return state;
    }
    case Business.GET_DATA_FROM_BUSINESS_FORM_SAGA: {
      if (payload || payload === null) {
        return state.set('business', fromJS(payload));
      }
      return state;
    }
    case Auth.LOG_OUT: {
      return state.set('address', fromJS([]))
        .set('name', null)
        .set('email', null)
        .set('password', null)
        .set('business', null);
    }
    default:
      return state;
  }
};

export default reducer;
