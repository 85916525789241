import { fromJS } from 'immutable';
import { Auth } from './../constants/actions';

const initState = fromJS({
  token: null,
  currentUserUid: null,
  appType: null,
  currentUser: null
});

const reducer = (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case Auth.LOG_IN_SAGA: {
      if (payload || payload === null) {
        const { token, userUid } = payload;
        return state.set('token', fromJS(token)).set('currentUserUid', fromJS(userUid));
      }
      return state;
    }
    case Auth.SET_APP_TYPE: {
      if (payload || payload === null) {
        return state.set('appType', fromJS(payload));
      }
      return state;
    }
    case Auth.UPDATE_USER: {
      if (payload || payload === null) {
        const { token, userUid } = payload;
        return state.set('token', fromJS(token)).set('currentUserUid', fromJS(userUid));
      }
      return state;
    }
    case Auth.SET_CURRENT_USER_SAGA: {
      if (payload || payload === null) {
        return state.set('currentUser', fromJS(payload));
      }
      return state;
    }
    case Auth.LOG_OUT: {
      return state.set('currentUserUid', null)
        .set('token', null)
        .set('currentUserUid', null);
    }
    default:
      return state;
  }
};

export default reducer;
