import { put, takeEvery, call, fork } from 'redux-saga/effects';
import { User, Components, Auth, Business } from './../constants/actions';
import { replace, push } from 'react-router-redux';
import * as Api from '../api/index';
import fetchWrapper from './fetchWrapper';
import history from '../history';

function* updateTableItems(response, actionType) {
  const { total: totalElements, items, page: currentPage } = response;
  const params = new URLSearchParams(history.location.search);
  params.set('page', currentPage);
  yield put(replace({ search: `?${params.toString()}`, params: currentPage }));
  yield put({ type: User[actionType], payload: { items, totalElements, currentPage } });
  yield put({ type: Components.CLEAR_SELECTED_ITEMS_SAGA, payload: true });
}

function* resetUserPassword({ payload }) {
  let resetPasswordSuccess = yield call(Api.resetUserPassword, payload);
  if (resetPasswordSuccess) {
    // yield put({ type: User.RESET_USER_PASSWORD_SAGA, payload: true });
  }
}

function* onUserActivateAccount({ payload }) {
  yield call(Api.onUserActivateAccount, payload);
}

function* onChangeUserItemStatus({ payload }) {
  let responseStatusCode = yield fetchWrapper(call(Api.onChangeItemByStatus, payload));
  if (responseStatusCode === 200) {
    let response = yield call(Api.getUserAllItemsList, payload);
    if (response) {
      yield updateTableItems(response, 'GET_ALL_USER_ITEMS_SAGA');
    }
  }
}

function* onChangeUserInfo({ payload }) {
  let response = yield call(Api.onChangeUserInfo, payload);
  if (response) {
    yield put({ type: User.ON_CHANGE_USER_INFO_SAGA, payload: { value: response, fieldType: payload.fieldType } });
    yield put({ type: Business.GET_LOCATION_LIST_SAGA, payload: [] });
  }
}

function* onDeleteUserItems({ payload }) {
  let isDeleted = yield call(Api.onDeleteItems, payload);
  if (isDeleted) {
    switch (payload.accountType) {
      case 'Members' :
        yield getMembersItems({ payload });
        break;
      case 'Business' :
        yield getBusinessUserItemsList({ payload });
        break;
      default:
        yield getUserAllItemsList({ payload });
    }
  }
}

function* onChangePassword({ payload }) {
  let response = yield fetchWrapper(call(Api.onChangePassword, payload));
  if (response) {
    yield logOut();
    yield put({ type: Auth.LOG_OUT });
    yield put(push('/'));
  }
}

function* logOut() {
  const response = yield fetchWrapper(call(Api.logOut));
}

function* getBusinessMembers({ payload }) {
  let response = yield fetchWrapper(call(Api.getBusinessMembers, payload));
  if (response) {
    yield updateTableItems(response, 'GET_BUSINESS_MEMBERS_SAGA');
  }
}

function* onDeleteAccount({ payload }) {
  const { userUid, redirectTo } = payload;
  let response = yield fetchWrapper(call(Api.onDeleteAccount, { userUid }));
  if (response) {
    if (redirectTo) {
      yield put(push(redirectTo));
      return null;
    }
    yield getBusinessMembers({ payload });
  }
}

function* getUserInfo({ payload }) {
  let user = yield fetchWrapper(call(Api.getUserInfo, payload));
  // console.log(user, 'user');
  // if (user === 'Network Error') {
  //   console.log('Network Error');
  //   yield logOut();
  //   yield put({ type: Auth.LOG_OUT });
  //   yield put(push('/'));
  //   return
  // }
  if (user) {
    yield fork(getUserImg, user, 'GET_CURRENT_USER_IMG_SAGA');
    yield put({ type: User.GET_USER_INFO_SAGA, payload: user });
  }
  
}

function* getUserImg(user, actionType) {
  if (user && user.userProfilePicture) {
    const av = user.userProfilePicture && user.userProfilePicture.split('/');
    const imgUid = av[av.length - 1];
    const img = yield fetchWrapper(call(Api.getItemImage, { imgUid }), 'FETCHING_IMG');
    if (img) {
      yield put({ type: User[actionType], payload: { img, userUid: user.id } });
    }
  }
}

function* onMarkItemAsReturned({ payload }) {
  let responseStatusCode = yield call(Api.markItemAsReturned, payload);
  if (responseStatusCode === 'OK') {
    yield getMembersItems({ payload });
    yield getBusinessUserItemsList({ payload });
  }
}

function* onUploadUserImg({ payload }) {
  let responseStatusCode = yield call(Api.onUploadUserImg, payload);
  if (responseStatusCode === 200) {
    let uid = payload.userUid;
    let user = yield fetchWrapper(call(Api.getUserInfo, { uid }));
    if (user) {
      yield fork(getUserImg, user, 'GET_CURRENT_USER_IMG_SAGA');
    }
  }
}

function* getUserAllItemsList({ payload }) {
  let response = yield fetchWrapper(call(Api.getUserAllItemsList, payload));
  if (response) {
    yield updateTableItems(response, 'GET_ALL_USER_ITEMS_SAGA');
  }
}

function* getMembersItems({ payload }) {
  let response = yield fetchWrapper(call(Api.getMembersItems, payload));
  if (response) {
    yield updateTableItems(response, 'GET_BUSINESS_MEMBERS_ITEMS_SAGA');
  }
}

function* getBusinessUserItemsList({ payload }) {
  let response = yield fetchWrapper(call(Api.getBusinessUserItemsList, payload));
  if (response) {
    yield updateTableItems(response, 'GET_BUSINESS_USER_ITEMS_SAGA');
  }
}

function* getUserNotifications({ payload }) {
  let response = yield fetchWrapper(call(Api.getUserNotifications, payload), 'FETCHING_NOTIFICATIONS');
  if (response) {
    yield put({ type: User.GET_USER_NOTIFICATIONS_SAGA, payload: response });
  }
}

function* getUserLocations({ payload }) {
  let response = yield fetchWrapper(call(Api.onGetUserLocations, payload));
  if (response) {
    yield put({ type: User.GET_USER_LOCATIONS_SAGA, payload: response });
  }
}

export default function* user() {
  yield takeEvery(User.GET_USER_INFO, getUserInfo);
  yield takeEvery(User.RESET_USER_PASSWORD, resetUserPassword);
  yield takeEvery(User.USER_ACTIVATE_WITH_EMAIL, onUserActivateAccount);
  yield takeEvery(User.ON_CHANGE_ITEM_BY_NEW_STATUS, onChangeUserItemStatus);
  yield takeEvery(User.ON_DELETE_USER_ACCOUNT, onDeleteAccount);
  yield takeEvery(User.GET_ALL_USER_ITEMS, getUserAllItemsList);
  yield takeEvery(User.GET_BUSINESS_USER_ITEMS, getBusinessUserItemsList);
  yield takeEvery(User.ON_DELETE_ITEMS, onDeleteUserItems);
  yield takeEvery(User.GET_BUSINESS_MEMBERS_ITEMS, getMembersItems);
  yield takeEvery(User.ON_CHANGE_USER_INFO, onChangeUserInfo);
  yield takeEvery(User.ON_CREATE_NEW_PASSWORD, onChangePassword);
  yield takeEvery(Components.ON_MARK_ITEM_AS_RETURNED, onMarkItemAsReturned);
  yield takeEvery(User.ON_UPLOAD_USER_IMG, onUploadUserImg);
  yield takeEvery(Auth.LOG_OUT, logOut);
  yield takeEvery(User.GET_USER_NOTIFICATIONS, getUserNotifications);
  yield takeEvery(User.GET_USER_LOCATIONS, getUserLocations);
};