import { Spin, Select as S, Input as I, Button as B, Table as T, Modal as M } from 'antd';
import { Link as L } from 'react-router-dom';

import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { TEXT_SIZES } from '../../constants/textSizes';


const Type = {
  'facebook': {
    color: `${COLORS.secondary}`,
    colorHover: `${COLORS.secondary}`,
    background: `${COLORS.facebook}`,
    backgroundHover: `${COLORS.facebook_lighter}`,
    shadow: `${COLORS.shadow}`,
    borderColor: `1px solid ${COLORS.facebook}`,
    borderColorHover: `1px solid ${COLORS.facebook_lighter}`,
  },
  'twitter': {
    color: `${COLORS.secondary}`,
    colorHover: `${COLORS.secondary}`,
    background: `${COLORS.facebook}`,
    backgroundHover: `${COLORS.facebook_lighter}`,
    shadow: `${COLORS.shadow}`,
    borderColor: `1px solid ${COLORS.facebook}`,
    borderColorHover: `1px solid ${COLORS.facebook_lighter}`,
  },
  'primary': {
    color: `${COLORS.primary}`,
    colorHover: `${COLORS.violet}`,
    background: `none`,
    backgroundHover: `none`,
    shadow: `${COLORS.shadow}`,
    borderColor: `1px solid ${COLORS.grey}`,
    borderColorHover: `1px solid ${COLORS.violet}`,
  },
  'violet': {
    color: `${COLORS.violet}`,
    colorHover: `${COLORS.violet}`,
    background: `none`,
    backgroundHover: `none`,
    shadow: `${COLORS.shadow}`,
    borderColor: `1px solid ${COLORS.violet}`,
    borderColorHover: `1px solid ${COLORS.violet}`,
  },
  'pink': {
    color: `${COLORS.secondary}`,
    colorHover: `${COLORS.secondary}`,
    background: `${COLORS.pink}`,
    backgroundHover: `${COLORS.pink}`,
    shadow: `${COLORS.shadow}`,
    borderColor: `1px solid ${COLORS.pink}`,
    borderColorHover: `1px solid ${COLORS.pink}`,
  },
  'hollowpink': {
    color: `${COLORS.pink}`,
    colorHover: `${COLORS.pink}`,
    background: 'transparent',
    backgroundHover: 'transparent',
    shadow: `${COLORS.shadow}`,
    borderColor: `1px solid ${COLORS.pink}`,
    borderColorHover: `1px solid ${COLORS.pink}`,
  },
  'confirm': {
    color: `${COLORS.confirm}`,
    colorHover: `${COLORS.confirm}`,
    background: 'transparent',
    backgroundHover: 'transparent',
    shadow: `${COLORS.shadow}`,
    borderColor: `1px solid ${COLORS.confirm}`,
    borderColorHover: `1px solid ${COLORS.confirm}`,
  },
  'back': {
    color: `${COLORS.primary}`,
    colorHover: `${COLORS.primary}`,
    background: 'transparent',
    backgroundHover: 'transparent',
    shadow: 'none',
    borderColor: 'none',
    borderColorHover: 'none'
  },
};

export const BadgeType = {
  'lost': {
    color: `${COLORS.secondary}`,
    colorHover: `${COLORS.secondary}`,
    background: `${COLORS.pink}`,
    backgroundHover: `${COLORS.pink}`,
    shadow: `${COLORS.shadow}`,
    borderColor: `1px solid ${COLORS.pink}`,
    borderColorHover: `1px solid ${COLORS.pink}`,
  },
  'pending': {
    color: `${COLORS.secondary}`,
    colorHover: `${COLORS.secondary}`,
    background: COLORS.confirm,
    backgroundHover: COLORS.confirm,
    shadow: `${COLORS.shadow}`,
    borderColor: `1px solid ${COLORS.confirm}`,
    borderColorHover: `1px solid ${COLORS.confirm}`,
  }
};

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 88px);
  margin: 0 15px;
`;

export const StyledWrapper = styled.div`
  display: flex;
	justify-content: center;
	overflow: hidden;
	width: 100%;
	max-width: 1900px;
	margin: 0 auto;
	padding: 30px;
	cursor: default;
	@media (max-width: 1170px) {
		flex-direction: column;
		align-items: center;
	}
	@media (max-width: 550px) {
		flex-direction: column;
		align-items: center;
		padding: 20px;
	}
`;

export const Spinner = styled(Spin)`
	display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  height: auto;
`;

export const InfoElement = styled.div`
	display: block;
  border-top: 2px solid #eee;
	padding: 20px 30px;
	width: 100%;
	@media (max-width:1170px) {
		display: ${props => props.isToggleOn || props.show ? 'block' : 'none'};
	}
`;

export const Inputs = styled.div`
	display: flex;
	width: ${props => props.sm ? '200px' : '250px'};
	flex-direction: column;
	margin: 10px 30px 10px 0;
`;

export const Input = styled(I)`
	color:${COLORS.grey};
  font-size: ${TEXT_SIZES.sm};
  line-height: ${TEXT_SIZES.sm};
  border: none;
	border-bottom: 1px solid ${COLORS.inputUnselected};
	box-sizing: border-box;
	background: inherit;
	padding: 10px;
	height: 25px;
	width: 100%;
	border-radius: 0;
	&:hover, &:focus, &:active {
    border: none;
    border-bottom: 1px solid ${COLORS.grey};
    box-shadow: none;
    border-radius: 0;
  }
	>span{
		color:${COLORS.grey};
    font-size: ${TEXT_SIZES.sm};
    line-height: ${TEXT_SIZES.sm};
    border: none;
		border-bottom: 1px solid ${COLORS.inputUnselected};
		box-sizing: border-box;
		background: inherit;
		padding: 10px;
		height: 25px;
		width: 100%;
		border-radius: 0;
		&:hover, &:focus, &:active {
      border: none;
      border-bottom: 1px solid ${COLORS.grey};
      box-shadow: none;
      border-radius: 0;
	  }
	}
`;

export const Select = styled(S)`
	width: 100%;
	padding: 5px;
	color: #333;
	border: none;
	background: rgba(0,0,0,0.07);
	border-radius: 5px;
	box-shadow: inset 0 1px 2px rgba(0,0,0,0.15);
	box-sizing: border-box;
	height: auto;
	font-size: 16px;
	font-family: 'Cabin', sans-serif;
	vertical-align: middle;
	margin-top: 5px;
	div{
		background:none;
		border: none;
		padding-bottom: 0;
		&:focus, &:hover  {
			border: none;
			box-shadow:none;
		}
	}
`;

export const StatusBadge = styled.div`
  margin: 0;
	height: 100%;
  width: auto;
	border-radius: 15px;
	display: block;
	padding: 2px 10px;
	text-align: center;
	font-size: ${TEXT_SIZES.sm};
  
  background: ${props => BadgeType[props.type] && BadgeType[props.type].background};
	color: ${props => BadgeType[props.type] && BadgeType[props.type].color};
	border: ${props => BadgeType[props.type] && BadgeType[props.type].borderColor};
	&:hover{
		text-decoration: none;
	}
`;

export const Button = styled(B)`
	margin: 0;
	height: 100%;
	max-width: 100%;
	border-radius: ${props => props.small ? '15px' : '30px'};
	display: ${props => props.hidden ? 'none' : 'block'};
	padding: ${props => props.small ? '2px 10px' : '10px 20px'};
	font-size: ${props => props.small ? `${TEXT_SIZES.xs}` : `${TEXT_SIZES.sm}`};
	cursor: pointer;
	background: ${props => Type[props.type] && Type[props.type].background};
	color: ${props => Type[props.type] && Type[props.type].color};
	border: ${props => Type[props.type] && Type[props.type].borderColor};
	&:hover, &:focus, &:active {
		color: ${props => Type[props.type] && Type[props.type].colorHover};
		background: ${props => Type[props.type] && Type[props.type].backgroundHover};
		border: ${props => Type[props.type] && Type[props.type].borderColorHover};
		box-shadow: ${props => Type[props.type] && Type[props.type].shadow};
	}
`;

export const DisabledButton = styled(Button)`
 &[disabled]{
    opacity: 0.5;
    background-color: #E74E68;
    color: white;
    &:hover{
      background-color: #E74E68;
      color: white;
    }
 }
`;

export const Buttons = styled.div`
	display: flex;
	align-items: center;
	justify-content: ${props => props.small ? 'flex-start' : 'space-between'};
	padding: ${props => props.small ? '10px 0' : '0'};
  width: 100%;
	button:last-child{
		margin-left: 30px;
	}
`;

export const Text = styled.div`
  font-size:  ${({ size }) => TEXT_SIZES[size]};
  font-weight: 600;
  color: ${({ color }) => COLORS[color]};
  text-transform: ${props => props.uppercase ? 'uppercase' : 'none'};
`;

export const Link = styled(L)`
  width: 100%;
  font-size: inherit;
  color: ${COLORS.primary};
  text-align: center;
  &:hover{
    color: ${COLORS.secondary};
    font-size: inherit;
  }
`;

export const Search = styled(I.Search)`
	.ant-input-prefix, .ant-input-suffix{
		z-index: 1;
	}
	.ant-input:not(.ant-input-disabled){
		&:hover, &:active, &:focus{
			border: 1px solid white;
		  outline: 0;
		}
	}
	width: 300px;
	border: 1px solid transparent;
	input{
		box-sizing: border-box;
		border: 1px solid white;
		width: 100%;
		height: 40px;
		padding: 10px 10px 10px 20px;
		border-radius: 30px;
		font-size: ${TEXT_SIZES.sm};
		background: white;
		&:hover, &:active, &:focus{
			box-shadow: ${COLORS.shadow};
    	outline: 0;
		}
		@media (max-width: 899px) {
			//display: none;
		}
	}
	&:hover, &:active, &:focus{
    outline: 0;
	}
`;

export const Table = styled(T)`
	width: 100%;
	background:white;
	border-radius: 20px;
	padding: 20px;
	margin: 0 auto 20px;
  .ant-spin-nested-loading > div > .ant-spin{
    max-height: none;
  }
	.ant-table-pagination.ant-pagination{
		margin: 10px 0 0;
	}
	.ant-form-item-control{
	  line-height:inherit;
	}
	ul{
		li{
			border-radius: 50%;
			margin-right: 0;
			height: auto;
			line-height: unset;
			min-width: 0;
			border-color: white;
			a{
				border: none;
				color: ${COLORS.primary};
			}
			&:hover, &:active, &:focus{
				border-color: white;
				a{
					color: ${COLORS.primary};
					font-weight: 600;
				}
			}
			&:first-child, &:last-child {
				a{
					border:none;
					&:hover, &:active, &:focus{
						color: ${COLORS.primary};
					}
				}
			}
		}
		.ant-pagination-item-active {
			background-color: ${COLORS.violetLight};
			a{
				color: ${COLORS.violet};
				font-weight: 600;
				&:hover, &:active, &:focus{
					color: white;
				}
			}
			&:hover, &:active, &:focus{
				border-color: white;
				a{
					color: ${COLORS.violet};
				}
			}
		}
	}
	thead{
		text-transform: uppercase;
  	font-size: ${TEXT_SIZES.xs};
  	line-height: 15px;
  	border-bottom: 2px solid ${COLORS.lightGrey};
	}
	tbody {
  	font-size: ${TEXT_SIZES.sm};
  	>tr {
      transition: none;
			&:hover {
        cursor: pointer;
				background: ${COLORS.tableHover};
        td{
          color: ${COLORS.violet};
          text-shadow:0 0 0.1px ${COLORS.violet};
        }
      }
    }
    
    
	  tr.Lost, tr.Found, tr.returned, tr.ant-table-row{
	    color: ${COLORS.primary};
       &:hover {
        td{
          color: ${COLORS.primary};
          text-shadow:0 0 0.1px ${COLORS.primary};
          text-decoration: underline;
        }
      }
    }
	  >tr.ant-table-row-selected {
      &:hover {
        td{
          text-decoration: none;
        }
      }
	  }
    tr.Lost{
      background:${COLORS.pinkLight};
      &.ant-table-row-selected {
        td{
          color: ${COLORS.pink};
          text-shadow:0 0 0.1px ${COLORS.pink};
        }
      }
      >td:last-child{
        position: relative;
        &:after{
          content: 'Lost';
          position: absolute;
          padding: ${props => props.allItems === 'allItemsStyle' ? '2px 12px' : '0 8px'};
          top: ${props => props.allItems === 'allItemsStyle' ? '30%' : '-20%'};
          //right: ${props => props.allItems === 'allItemsStyle' ? 'auto' : '8px'};
          right: 15px;
          width: auto;
          //left: ${props => props.allItems === 'allItemsStyle' ? 'calc(50% - 20px)' : 'auto'};
          height: auto;
          vertical-align: baseline;
          color: ${COLORS.secondary};
          background: ${COLORS.pink};
          border-radius: ${props => props.allItems === 'allItemsStyle' ? '8px' : '10px'};
          font-size: ${TEXT_SIZES.xs};
          display: ${props => props.allItems === 'allItemsStyle' ? 'block' : 'none'};
        }
      }
    }
    
    tr.Pending{
      background:${COLORS.secondary};
      td{
         color: ${COLORS.grey};
      }
      &:hover{
        td{
          color: ${COLORS.grey};
        }
      }
   
      &.ant-table-row-selected {
        td{
          color: ${COLORS.grey};
          text-shadow:0 0 0.1px ${COLORS.confirm};
        }
      }
      >td:last-child{
        position: relative;
        &:after{
          content: 'Pending';
          position: absolute;
          padding: ${props => props.allItems === 'allItemsStyle' ? '2px 12px' : '0 8px'};
          top: ${props => props.allItems === 'allItemsStyle' ? '30%' : '-20%'};
          right: 15px;
          width: auto;
          height: auto;
          vertical-align: baseline;
          color: ${COLORS.secondary};
          background: ${COLORS.confirm};
          border-radius: ${props => props.allItems === 'allItemsStyle' ? '8px' : '10px'};
          font-size: ${TEXT_SIZES.xs};
          display: ${props => props.allItems === 'allItemsStyle' ? 'block' : 'none'};
        }
      }
    }
    tr.Found{
      &.ant-table-row-selected {
        td{
          color: ${COLORS.violet};
          text-shadow:0 0 0.1px ${COLORS.violet};
        }
      }
    }
  }
	thead > tr > th, tbody > tr > td{
		padding: 15px;
		color: ${COLORS.primary};
		border: none;
	}
	thead > tr > th.ant-table-column-sort{
		color: black;
    font-weight: 700;
	}
	thead > tr > th:first-child, tbody > tr > td:first-child{
		padding: ${props => props.goodsType ? '15px 15px' : ' 15px 30px'}
	}
	// .ant-table-tbody > tr.ant-table-row-selected td{
	// 	background:${COLORS.pinkLight};
	// 	color: ${COLORS.blue};
	// 	text-shadow:0 0 0.1px ${COLORS.pink};
	// }
	.ant-table-tbody {
     tr > td {
     .ant-form-explain{
        background: white;
      }
       &.ant-table-column-sort{
          background: inherit;
      }
     }
	}
	thead > tr{
		> th{
			background-color: white;
		}
		&:first-child > th:first-child{
			border-top-left-radius: 20px;
		}
	}
	tbody > tr{
		> td{
			border-bottom: 2px solid white;
		}
	}

	.ant-table-thead > tr > th.ant-table-column-sort{
	  background:none;
	}
	.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover{
	  background:none;
	}

	
	.ant-checkbox-wrapper:hover .ant-checkbox-inner,
	.ant-checkbox:hover .ant-checkbox-inner,
	.ant-checkbox-input:focus + .ant-checkbox-inner{
		border-color:${COLORS.checked};
	}
	.ant-checkbox{
		&:hover{
				border-color:${COLORS.checked};
			}
	}
	.ant-checkbox-wrapper{
		.ant-checkbox-inner{
			&:focus{
				border-color:${COLORS.checked};
			}
			&:after{
				background-color:${COLORS.checked};
			}
		}
	}
	.ant-checkbox-checked .ant-checkbox-inner{
		background:${COLORS.checked};
		border-color:${COLORS.checked};
		&:after{
			background-color:${COLORS.checked};
		}
		&:focus{
			border-color:${COLORS.checked};
		}
	}
	.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
	.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
	.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
	.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
	  background: #F7F5FC;
    .ant-form-explain{
      background: #F7F5FC;
    }
	}
	.has-error .ant-input:not([disabled]):hover {
      box-shadow: none;
  }
`;

export const Modal = styled(M)`
	margin: 0 auto;
	.ant-modal-content{
    border-radius: 15px;
    padding: 0;
    .ant-modal-body{
      padding: 0;
      min-width: 380px;
    }
  }
`;

export const DeleteLink = styled.div`
  color: ${COLORS.pink};
  font-size: ${TEXT_SIZES.base};
  text-decoration: underline;
`;

export const Action = styled.div`
  color: ${COLORS.pink};
  font-size: ${TEXT_SIZES.sm};
  text-decoration: underline;
`;

export const PopupWrapper = styled.div`
  display: flex;
  padding: ${props => props.withoutPadding ? '0' : '40px 60px'};
  flex-direction: ${props => props.flexDirection == 'row' ? 'row' : 'column'};
  justify-content: space-between;
  align-items:  ${props => props.alignItems == 'flex-start' ? 'flex-start' : 'center'};
  max-width: 500px;
`;

export const Tag = styled.div`
  display: inline-block;
  color: ${COLORS.tags};
  border-radius: 20px;
  border: 1px solid ${COLORS.tags};
  text-align: center;
  padding: 5px 10px;
`;