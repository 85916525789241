import { Card as C, Form as F, Input as I, InputNumber as IN, Radio, Checkbox as Ch } from 'antd';
import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { TEXT_SIZES } from '../../constants/textSizes';

export const Form = styled(F)`
  color:${COLORS.primary};
`;
export const FormItem = styled(Form.Item)`
  color:${COLORS.primary};
  font-size: ${TEXT_SIZES.sm};
  line-height: 27px;
  margin-bottom: 20px;
  .ant-form-item-label{
    line-height: 20px;
  }
  .ant-form-explain{
    position: absolute;
    font-size: ${TEXT_SIZES.xs};
    margin-top: 1px;
    width: max-content;
	}
    .success{
      border: 1px solid ${COLORS.lightBlue};
      input{
        border-color: ${COLORS.lightBlue};
      }
      &:hover, &:focus, &:active {
        border: 1px solid ${COLORS.lightBlue};
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        input{
          border-color: ${COLORS.lightBlue};
        }
      }
    }
 }
`;
export const Wrapper = styled.div`
	padding: 30px 30px 40px 30px;
  box-sizing: border-box;
  @media (max-width:420px) {
 		padding: 15px;
	}
`;
export const Card = styled(C)`
	width: 500px;
	height: auto;
	padding:25px 60px 40px;
	background: white;
	border-radius: 8px;
	max-width: 100%;
	margin: 0 auto;
	border: none;
	img{
		width: 100%;
		height: 100%;
		max-height: 530px;
		background:white;
		padding: 25px;
		margin-bottom: 25px;
		border-radius: 5px;
	}
	.ant-card-body{
	  padding: 0;
	}
	
`;
export const Header = styled.header`
	padding: 0 30px;
	background: ${({ bgcolor }) => COLORS[bgcolor]};
`;
export const Title = styled.h1`
  font-size: ${TEXT_SIZES.xl};
  font-weight: bold;
  line-height: 1;
  color: ${COLORS.primary};
  text-align: center;
  margin-bottom: 25px;
  @media (max-width:768px) {

	}
`;
export const Lable = styled.div`
  font-size: ${TEXT_SIZES.sm};
  line-height: 27px;
  color: ${COLORS.primary};
  text-align: center;
  margin-bottom: 35px;
  @media (max-width:768px) {

	}
`;
export const Inner = styled.div`
	position: relative;
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 0 60px 0;
  text-align: center;
`;
export const Input = styled(I)`
  color:${COLORS.grey};
  font-size: ${TEXT_SIZES.sm};
  border: 1px solid ${COLORS.inputUnselected};
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding: 10px 12px;
  &:hover, &:focus, &:active {
    border: 1px solid ${COLORS.inputFocused};
  }
`;
export const InputExtra = styled.div`
  margin-top: 15px;
  color:${COLORS.primary};
	font-size: ${TEXT_SIZES.xs};
	line-height: 16px;
	font-family: 'Nunito', sans-serif;
`;

export const TextArea = styled(I.TextArea)`
	width: 100%;
	padding: 10px 12px;
	color: ${COLORS.primary};
	border: none;
	background: rgba(0,0,0,0.07);
	border-radius: 5px;
	box-shadow: inset 0 1px 2px rgba(0,0,0,0.15);
	box-sizing: border-box;
	height: auto;
	font-size: 16px;
	vertical-align: middle;
	margin-top: 5px;
`;

export const InputNumber = styled(IN)`
  width: 60px;
  height: 40px;
  border-radius:0;
  border: 1px solid ${COLORS.grey};
  border-right: none;
  line-height: 40px;
`;

export const RButton = styled(Radio.Button)`
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  text-align: center;
  background: ${COLORS.secondary};
  color: ${COLORS.primary};
  border: 1px solid ${COLORS.violet};
  border-radius: 0;
  .ant-radio-button-checked{
    background: ${COLORS.violet};
    color: ${COLORS.secondary};
  }
  &:hover{
    color: ${COLORS.violet};
  }
`;

export const RGroup = styled(Radio.Group)`
  line-height:38px;
  .ant-radio-button-wrapper-checked{
    box-shadow: none;
  }
  .ant-radio-button-wrapper:first-child{
    border-radius: 0;
    border-left: 1px solid ${COLORS.violet};
  }
  .ant-radio-button-wrapper:last-child{
    //border-left: 0;
    border-radius: 0;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background: ${COLORS.violet};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    border: 1px solid ${COLORS.violet};
  }
  .ant-radio-button-wrapper:not(:first-child)::before{
    background:none;
  }
`;

export const Checkbox = styled(Ch)`
  @-webkit-keyframes upper {
    50% {
      transform: scale(1.00) translateX(3px);
    }
    85% {
      transform: scale(2) translateX(3px);
    }
    100% {
      transform: scale(1.0) translateX(3px);
    }
  }
  position: relative;
  margin: 0;
  width: 54px;
  height: 40px;
  text-align: center;
  display: flex;

  .ant-checkbox{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    .ant-checkbox-inner{
      background: ${COLORS.secondary};
      color: ${COLORS.secondary};
      width: 100%;
      height: 100%;
      border: none;
      &:hover{
        background: ${COLORS.pink};
        color: ${COLORS.primary};
        animation: upper 0.5s ease;
      }
    }
  }
  &.span-checked > span{
    color: ${COLORS.secondary};
     &:hover{
      color: ${COLORS.primary};
      background: ${COLORS.pink};
    }
  }
  .ant-checkbox-wrapper{
    display: flex;
  }
  >span:not(.ant-checkbox){
    position: absolute;
    width: 100%;
    border: 1px solid ${COLORS.violet};
    height: inherit;
    &:hover{
      color: ${COLORS.primary};
      background: ${COLORS.pink};
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${COLORS.violet};
    color: ${COLORS.secondary};
    &:hover{
      color: ${COLORS.pink};
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner + span {
      color: ${COLORS.secondary};
  }
  .ant-checkbox-checked .ant-checkbox-inner:after, .ant-checkbox-checked .ant-checkbox-inner:before{
    display: none;
  }
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  @-webkit-keyframes upper {
    50% {
      transform: scale(1.00) translateX(3px);
    }
    85% {
      transform: scale(2) translateX(3px);
    }
    100% {
      transform: scale(1.0) translateX(3px);
    }
  }
  label{
    margin: 0;
    width: 54px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    span{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      &:hover{
        background: ${COLORS.pink};
        color: ${COLORS.primary};
        animation: upper 0.2s ease;
      }
    }
    
  }
  label.ant-checkbox-group-item.ant-checkbox-wrapper{
    background: ${COLORS.secondary};
    color: ${COLORS.primary};
    border: 1px solid ${COLORS.violet};
    border-radius: 0;
    border-left: none;
    &:first-child{
      border-left: 1px solid ${COLORS.violet};
    }
    >span.ant-checkbox{
      display: none;
    }
    &.ant-checkbox-wrapper-checked{
     background: ${COLORS.violet};
     color: ${COLORS.secondary};
    }
  }
`;