import { fork } from 'redux-saga/effects';
import user from './user';
import users from './users';
import components from './components';
import auth from './auth';
import business from './business';
import members from './members';
import admins from './admins';
import deliveries from './deliveries';
import payments from './payments';

function* mySaga() {
  yield fork(auth);
  yield fork(user);
  yield fork(business);
  yield fork(components);
  yield fork(members);
  yield fork(admins);
  yield fork(users);
  yield fork(deliveries);
  yield fork(payments);
}

export default mySaga;