import { fromJS } from 'immutable';
import { Components, Auth, User } from './../constants/actions';

const initState = fromJS({
  isResetting: null,
  items: [],
  isFetching: false,
  isFetchingImg: false,
  img: null,
  totalElements: null,
  currentPage: null,
  isClearSelectedItems: false,
  lostItem: {},
  foundItem: {},
  isFetchingLostItemImg: false,
  isFetchingFoundItemImg: false,
  lostItemImg: null,
  foundItemImg: null,
  isFetchingNotifications: false,
  partnerInfo: {},
  userInfo: {},
  addressData: {},
  notFoundLostItem: false,
  locationId: null,
  isFetchingPayments: null
});

const reducer = (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case Components.GET_ITEMS_BY_FILTER_SAGA: {
      if (payload || payload === null) {
        const { items, totalElements, currentPage } = payload;
        return state.set('items', fromJS(items)).set('totalElements', fromJS(totalElements)).set('currentPage', fromJS(currentPage));
      }
      return state;
    }
    case Components.CLEAR_SELECTED_ITEMS_SAGA: {
      return state.set('isClearSelectedItems', fromJS(payload));
    }
    case Components.FETCHING_SAGA: {
      return state.set('isFetching', fromJS(payload));
    }
    case Components.GET_IMAGE_FOR_ITEM_SAGA: {
      if (payload || payload === null) {
        return state.set('img', fromJS(payload));
      }
      return state;
    }
    case Components.FETCHING_IMG: {
      return state.set('isFetchingImg', fromJS(payload));
    }
    case Components.GET_LOST_ITEM_BY_ID: {
      return state.set('lostItem', fromJS(payload));
    }
    case Components.GET_FOUND_ITEM_BY_ID: {
      return state.set('foundItem', fromJS(payload));
    }
    case Components.GET_LOST_ITEM_IMG_SAGA: {
      return state.set('lostItemImg', fromJS(payload));
    }
    case Components.GET_FOUND_ITEM_IMG_SAGA: {
      return state.set('foundItemImg', fromJS(payload));
    }
    case Components.FETCHING_LOST_ITEM_IMG: {
      return state.set('isFetchingLostItemImg', fromJS(payload));
    }
    case Components.FETCHING_FOUND_ITEM_IMG: {
      return state.set('isFetchingFoundItemImg', fromJS(payload));
    }
    case Components.FETCHING_NOTIFICATIONS: {
      return state.set('isFetchingNotifications', fromJS(payload));
    }
    case Components.GET_PARTNER_INFO: {
      return state.set('partnerInfo', fromJS(payload));
    }
    case Components.GET_USER_INFO: {
      return state.set('userInfo', fromJS(payload));
    }
    case Components.NOT_FOUND_LOST_ITEM: {
      return state.set('notFoundLostItem', fromJS(true));
    }
    case Components.GET_ADDRESS_BY_ID_SAGA: {
      return state.set('addressData', fromJS(payload));
    }
    case Components.ADD_NEW_LOCATION_SAGA: {
      return state.set('locationId', fromJS(payload));
    }
    case Components.FETCHING_PAYMENTS: {
      return state.set('isFetchingPayments', fromJS(payload));
    }
    case Auth.LOG_OUT: {
      return state.set('isResetting', null)
        .set('items', fromJS([]))
        .set('isFetching', false)
        .set('img', null)
        .set('totalElements', null)
        .set('currentPage', null)
        .set('isClearSelectedItems', false);
    }
    default:
      return state;
  }
};

export default reducer;


