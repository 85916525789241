import { createSelector } from 'reselect';

export const getComponentsData = (state) => state.components;

export const getItemsData = createSelector(
  [getComponentsData],
  (data) => data.get('items').toJS()
);

export const isClearSelectedItems = createSelector(
  [getComponentsData],
  (data) => {
    return data.get('isClearSelectedItems');
  }
);

export const getImageData = createSelector(
  [getComponentsData],
  (data) => {
    return data.get('img');
  }
);

export const itemsTotalElements = createSelector(
  [getComponentsData],
  (data) => {
    return data.get('totalElements');
  }
);

export const getCurrentPage = createSelector(
  [getComponentsData],
  (data) => {
    return data.get('currentPage');
  }
);

export const getIsFetching = createSelector(
  [getComponentsData],
  (data) => data.get('isFetching')
);

export const getIsFetchingImg = createSelector(
  [getComponentsData],
  (data) => data.get('isFetchingImg')
);

export const getLostItemForMatching = createSelector(
  [getComponentsData],
  (data) => data.get('lostItem').toJS()
);

export const getFoundItemForMatching = createSelector(
  [getComponentsData],
  (data) => data.get('foundItem').toJS()
);

export const isFetchingLostItemImg = createSelector(
  [getComponentsData],
  (data) => data.get('isFetchingLostItemImg')
);

export const isFetchingFoundItemImg = createSelector(
  [getComponentsData],
  (data) => data.get('isFetchingFoundItemImg')
);

export const getFoundItemImg = createSelector(
  [getComponentsData],
  (data) => data.get('foundItemImg')
);

export const getLostItemImg = createSelector(
  [getComponentsData],
  (data) => data.get('lostItemImg')
);

export const isFetchingNotifications = createSelector(
  [getComponentsData],
  (data) => data.get('isFetchingNotifications')
);
export const getPartnerInfo = createSelector(
  [getComponentsData],
  (data) => data.get('partnerInfo').toJS()
);

export const getUserInfo = createSelector(
  [getComponentsData],
  (data) => data.get('userInfo').toJS()
);
export const notFoundLostItem = createSelector(
  [getComponentsData],
  (data) => data.get('notFoundLostItem')
);

export const getAddressData = createSelector(
  [getComponentsData],
  (data) => data.get('addressData').toJS()
);

export const getLocationId = createSelector(
  [getComponentsData],
  (data) => data.get('locationId')
);

export const getIsFetchingPayments = createSelector(
  [getComponentsData],
  (data) => data.get('isFetchingPayments')
);
