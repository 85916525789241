const autoPrefix = (prefix) => callback => callback(prefix);

export const Components = autoPrefix('components_action_')(prefix => {
  return {
    CLOSE_POPUP: `${prefix}close_popup`,
    OPEN_POPUP: `${prefix}open_popup`,
    CONFIRM_DELIVERY_POPUP: `${prefix}confirm_delivery_popup`,
    CONFIRM_POPUP: `${prefix}confirm_popup`,
    
    ADDING_WORKING_OURS: `${prefix}adding_working_ours`,
    CLEAR_SELECTED_ITEMS: `${prefix}clear_selected_items`,
    CLEAR_SELECTED_ITEMS_SAGA: `${prefix}clear_selected_items_saga`,
    DELETE_ITEM: `${prefix}delete_item`,
    ON_CHANGE_ITEM_BY_NEW_STATUS: `${prefix}on_marked_item_by_new_status`,
    GET_ITEMS_BY_FILTER: `${prefix}get_items_by_filter`,
    GET_ITEMS_BY_FILTER_SAGA: `${prefix}get_items_by_filter_saga`,
    FETCHING_SAGA: `${prefix}fetching_saga`,
    ON_UPDATE_ITEM_FIELD: `${prefix}on_update_item_field`,
    ON_UPDATE_ITEM_FIELD_SAGA: `${prefix}on_update_item_field_saga`,
    GET_IMAGE_FOR_ITEM: `${prefix}get_image_for_item`,
    GET_IMAGE_FOR_ITEM_SAGA: `${prefix}get_image_for_item_saga`,
    FETCHING_IMG: `${prefix}fetching_img`,
    ON_MARK_ITEM_AS_RETURNED: `${prefix}on_mark_item_as_returned`,
    ON_MARK_ITEM_AS_RETURNED_SAGA: `${prefix}on_mark_item_as_returned_saga`,
    ON_GET_MATCHING_ITEMS: `${prefix}on_get_matching_items`,
    ON_GET_MATCHING_ITEMS_SAGA: `${prefix}on_get_matching_items_saga`,
    SELECTED_ITEM_FOR_MATCHING: `${prefix}selected_item_for_matching`,
    ON_MATCH_LOST_FOUND_ITEMS: `${prefix}on_match_lost_found_items`,
    GET_FOUND_ITEM_IMG: `${prefix}get_found_item_img`,
    GET_FOUND_ITEM_IMG_SAGA: `${prefix}get_found_item_img_saga`,
    FETCHING_FOUND_ITEM_IMG: `${prefix}fetching_found_item_img`,
    GET_LOST_ITEM_IMG: `${prefix}get_lost_item_img`,
    GET_LOST_ITEM_IMG_SAGA: `${prefix}get_lost_item_img_saga`,
    FETCHING_LOST_ITEM_IMG: `${prefix}fetching_lost_item_img`,
    FETCHING_NOTIFICATIONS: `${prefix}fetching_notifications`,
    CONFIRM_POPUP_MARK_ITEM_AS_PENDING: `${prefix}confirm_popup_mark_item_as_pending`,
    GET_ITEM_BY_ID: `${prefix}get_item_by_id`,
    GET_LOST_ITEM_BY_ID: `${prefix}get_lost_item_by_id`,
    GET_FOUND_ITEM_BY_ID: `${prefix}get_found_item_by_id`,
    ON_CANCELLED_MATCHING_ITEMS: `${prefix}on_cancelled_matching_items`,
    ON_CONFIRM_MATCHING_ITEMS: `${prefix}on_confirm_matching_items`,
    GET_ITEMS_MATCHING_BY_MATCH_ID: `${prefix}get_items_matching_by_match_id`,
    GET_PARTNER_INFO: `${prefix}get_partner_info`,
    GET_USER_INFO: `${prefix}get_user_info`,
    NOT_FOUND_LOST_ITEM: `${prefix}not_found_lost_item`,
    GET_ADDRESS_BY_ID: `${prefix}get_address_by_id`,
    GET_ADDRESS_BY_ID_SAGA: `${prefix}get_address_by_id_saga`,
    ADD_NEW_LOCATION: `${prefix}add_new_location`,
    ADD_NEW_LOCATION_SAGA: `${prefix}add_new_location_saga`,
    FETCHING_PAYMENTS: `${prefix}fetching_payments`,
  };
});

export const User = autoPrefix('user_action_')(prefix => {
  return {
    SEND_INVITE: `${prefix}send_invite`,
    CONSOLE: `${prefix}console`,
    GET_USERS_LIST: `${prefix}get_users_list`,
    GET_USERS_LIST_SAGA: `${prefix}get_users_list_saga`,
    GET_USER_INFO: `${prefix}get_user_info`,
    GET_USER_INFO_SAGA: `${prefix}get_user_info_saga`,
    RESET_USER_PASSWORD: `${prefix}reset_user_password`,
    RESET_USER_PASSWORD_SAGA: `${prefix}reset_user_password_saga`,
    USER_ACTIVATE_WITH_EMAIL: `${prefix}user_activate_with_email`,
    ON_CHANGE_USER_INFO: `${prefix}on_change_user_info`,
    ON_CHANGE_USER_INFO_SAGA: `${prefix}on_change_user_info_saga`,
    GET_USER_ITEMS_FOUND: `${prefix}get_user_items_found`,
    GET_USER_ITEMS_FOUND_SAGA: `${prefix}get_user_items_found_saga`,
    GET_USER_ITEMS_LOST: `${prefix}get_user_items_lost`,
    GET_USER_ITEMS_LOST_SAGA: `${prefix}get_user_items_lost_saga`,
    GET_USER_ITEMS_PREREGISTERED: `${prefix}get_user_items_preregistered`,
    GET_USER_ITEMS_PREREGISTERED_SAGA: `${prefix}get_user_items_preregistered_saga`,
    ON_DELETE_ITEMS: `${prefix}on_delete_items`,
    ON_DELETE_ITEMS_SAGA: `${prefix}on_delete_items_saga`,
    ON_DELETE_USER_ACCOUNT: `${prefix}on_delete_user_account`,
    ON_DELETE_USER_ACCOUNT_SAGA: `${prefix}on_delete_user_account_saga`,
    ON_DELETE_USER: `${prefix}on_delete_user`,
    ON_DELETE_USER_SAGA: `${prefix}on_delete_user_saga`,
    GET_USER_ITEMS: `${prefix}get_user_items`,
    GET_USER_ITEMS_SAGA: `${prefix}get_user_items_saga`,
    // ADD_WORKING_HOURS: `${prefix}add_working_hours`,
    // ADD_NEW_PHONE_NUMBER: `${prefix}add_new_phone_number`,
    GET_USERS_BY_SEARCH: `${prefix}get_users_by_search`,
    GET_ALL_USER_ITEMS_SAGA: `${prefix}get_all_user_items_saga`,
    GET_ALL_USER_ITEMS: `${prefix}get_all_user_items`,
    ON_CHANGE_ITEM_BY_NEW_STATUS: `${prefix}on_change_item_by_new_status`,
    GET_BUSINESS_MEMBERS: `${prefix}get_business_members`,
    GET_BUSINESS_MEMBERS_SAGA: `${prefix}get_business_members_saga`,
    GET_BUSINESS_MEMBERS_ITEMS: `${prefix}get_business_members_items`,
    GET_BUSINESS_MEMBERS_ITEMS_SAGA: `${prefix}get_business_members_items_saga`,
    ON_CREATE_NEW_PASSWORD: `${prefix}on_create_new_password`,
    ADD_NEW_FOUND_ITEM: `${prefix}add_new_found_item`, //delete
    ON_UPDATE_GEOLOCATION: `${prefix}on_update_geolocation`,
    ON_UPDATE_GEOLOCATION_SAGA: `${prefix}on_update_geolocation_saga`,
    GET_USER_IMG_SAGA: `${prefix}get_user_img_saga`,
    GET_CURRENT_USER_IMG_SAGA: `${prefix}get_current_user_img_saga`,
    ON_UPLOAD_USER_IMG: `${prefix}on_upload_user_img`,
    GET_PUBLIC_USER_ITEMS: `${prefix}get_public_user_items`,
    GET_PUBLIC_USER_ITEMS_SAGA: `${prefix}get_public_user_items_saga`,
    GET_BUSINESS_USER_ITEMS: `${prefix}get_business_user_items`,
    GET_BUSINESS_USER_ITEMS_SAGA: `${prefix}get_business_user_items_saga`,
    GET_USER_NOTIFICATIONS: `${prefix}get_user_notifications`,
    GET_USER_NOTIFICATIONS_SAGA: `${prefix}get_user_notifications_saga`,
    GET_USER_LOCATIONS: `${prefix}get_user_locations`,
    GET_USER_LOCATIONS_SAGA: `${prefix}get_user_locations_saga`,
  };
});

export const Business = autoPrefix('business_action_')(prefix => {
  return {
    ADD_NEW_BUSINESS: `${prefix}add_new_business`,
    ADD_NEW_BUSINESS_SAGA: `${prefix}add_new_business_saga`,
    GET_LOCATION_LIST: `${prefix}get_location_list`,
    GET_LOCATION_LIST_SAGA: `${prefix}get_location_list_saga`,
    GET_PASS_AND_EMAIL: `${prefix}get_pass_and_email`,
    GET_DATA_FROM_BUSINESS_FORM: `${prefix}get_data_from_business_form`,
    GET_DATA_FROM_BUSINESS_FORM_SAGA: `${prefix}get_data_from_business_form_saga`,
  };
});

export const Auth = autoPrefix('auth_action_')(prefix => {
  return {
    LOG_IN: `${prefix}log_in`,
    LOG_IN_SAGA: `${prefix}log_in_saga`,
    SIGN_UP: `${prefix}sign_up`,
    SIGN_UP_SAGA: `${prefix}sign_up_saga`,
    LOG_OUT: `${prefix}log_out`,
    SET_APP_TYPE: `${prefix}set_app_type`,
    UPDATE_USER: `${prefix}update_user`,
    GET_CURRENT_USER: `${prefix}get_current_user`,
    SET_CURRENT_USER_SAGA: `${prefix}set_current_user_saga`,
    ON_LOG_IN_BY_SOCIALS: `${prefix}on_log_in_by_socials`,
    ON_SIGN_UP_BY_SOCIALS: `${prefix}on_sign_up_by_socials`,
  };
});

export const Member = autoPrefix('member_action_')(prefix => {
  return {
    GET_BUSINESS_MEMBERS: `${prefix}get_business_members`,
    GET_BUSINESS_MEMBERS_SAGA: `${prefix}get_business_members_saga`,
    // GET_BUSINESS_MEMBERS_ITEMS: `${prefix}get_business_members_items`,
    // GET_BUSINESS_MEMBERS_ITEMS_SAGA: `${prefix}get_business_members_items_saga`,
    ON_CHANGE_MEMBER_ROLE: `${prefix}on_change_member_role`,
    ON_CHANGE_MEMBER_ROLE_SAGA: `${prefix}on_change_member_role_saga`,
    GET_BUSINESS_MEMBERS_BY_SEARCH: `${prefix}get_business_members_by_search`,
    GET_BUSINESS_MEMBER_IMG_SAGA: `${prefix}get_business_member_img_saga`,
    ON_DELETE_MEMBER_ACCOUNT: `${prefix}on_delete_member_account`,
    ON_DELETE_MEMBER_ACCOUNT_SAGA: `${prefix}on_delete_member_account_saga`,
  };
});

export const Admin = autoPrefix('admin_action_')(prefix => {
  return {
    GET_ADMINS_LIST: `${prefix}get_admins_list`,
    GET_ADMINS_LIST_SAGA: `${prefix}get_admins_list_saga`,
    GET_ADMIN_IMG_SAGA: `${prefix}get_admin_img_saga`,
    ON_DELETE_ADMIN_ACCOUNT: `${prefix}on_delete_admin_account`,
    ON_DELETE_ADMIN_ACCOUNT_SAGA: `${prefix}on_delete_admin_account_sagas`,
  };
});

export const Deliveries = autoPrefix('delivery_action_')(prefix => {
  return {
    GET_DELIVERIES_QUOTES: `${prefix}get_deliveries_quotes`,
    GET_DELIVERIES_QUOTES_SAGA: `${prefix}get_deliveries_quotes_saga`,
    ADD_DELIVERY_OPTIONS: `${prefix}add_delivery_options`,
    ADD_ITEM_SIZE: `${prefix}add_item_size`,
    ADD_PERSONAL_DETAILS: `${prefix}add_personal_details`,
    ADD_DELIVERY_DETAILS: `${prefix}add_delivery_details`,
    ON_ADD_SUCCESS_DELIVERY: `${prefix}on_add_success_delivery`,
    ON_ADD_MATCH_ID: `${prefix}on_add_match_id`,
  };
});
export const Payments = autoPrefix('payment_action_')(prefix => {
  return {
    ADD_APPROVED_PAYMENT: `${prefix}add_approved_payment`,
    ON_CREATE_PAYMENT: `${prefix}on_create_payment`,
   
  };
});