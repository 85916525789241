import { fromJS } from 'immutable';
import { User, Auth } from './../constants/actions';

const initState = fromJS({
  users: [],
  totalElements: null,
  currentPage: null,
});

const reducer = (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case User.GET_USERS_LIST_SAGA: {
      if (payload || payload === null) {
        const { items: users, totalElements, currentPage } = payload;
        return state.set('users', fromJS(users)).set('totalElements', fromJS(totalElements)).set('currentPage', fromJS(currentPage));
      }
      return state;
    }
    case User.ON_DELETE_USER_SAGA: {
      if (payload || payload === null) {
        const { userUid } = payload;
        return state.set('users', fromJS(state.get('users').filter(el => el.id !== userUid)));
      }
      return state;
    }
    case User.GET_USER_IMG_SAGA: {
      if (payload || payload === null) {
        const { userUid, img } = payload;
        const indexToUpdate = state.get('users').findIndex(user => user.get('id') === userUid);
        return state.setIn(['users', indexToUpdate, 'userProfilePicture'], fromJS(img));
      }
      return state;
    }
    case Auth.LOG_OUT: {
      return state.set('users', fromJS([]))
        .set('totalElements', null)
        .set('currentPage', null);
    }
    default:
      return state;
  }
};

export default reducer;
