export const BUSINESS_TYPE = [
  'AirBNB', 'Airport', 'Bar', 'Bus', 'Cafe', 'Event Space', 'Ferry', 'Garden Centre', 'Goverment venue', 'Hotel', 'Library', 'Museum/Art gallery', 'Nightclub', 'Other', 'Public venue', 'Restaurant', 'School', 'Sorts Stadium', 'Taxi', 'Train', 'University'
];

export const CATEGORY_TYPE = [
  'All', 'Animals', 'Art', 'Bike', 'Camera', 'Clothing', 'Decor', 'Display', 'Drone', 'Glasses', 'Handheld_Console', 'Instruments', 'Jewellery', 'Laptop', 'Luggage', 'Phone', 'Scooter', 'Shoes', 'Sports', 'Watch', 'Others'
];

export const INSURED_TYPE = [
  'Yes', 'No'
];

export const FILTER_TYPE = [
  'Lost', 'Found', 'ALL'
];

export const REGISTERED_TYPE = [
  'Business', 'Public', 'All'
];

export const STATUS_TYPES = {
  preregistered: 0,
  lost: 1,
  found: 2,
  returned: 3,
  pendingUserPreregisteredItem: 10,
  pendingUserLostItem: 11,
  pendingUserFoundItem: 12,
};

export const USER_ROLE = {
  superAdmin: 'FOUND_STAFF_MANAGEMENT',
  companyAdmin: 'COMPANY_ADMIN',
  businessUser: 'BUSINESS',
  publicUser: 'USER',
};

export const ITEM_TYPES = {
  0: 'preregistered',
  1: 'Lost',
  2: 'Found',
  3: 'returned',
  10: 'pendingPreregistered',
  11: 'pendingLost',
  12: 'penfdingFound',
  100: 'All',
  default: 'ALL'
};

export const CONFIRM_POPUP_MESSAGE = {
  markFound: {
    question: 'Are you sure you want to mark this item as Found?',
    subtextMarking: 'If you mark this item as Found we will stop searcing for it.',
    buttonText: 'Mark as Found',
    buttonType: 'confirm'
  },
  markReturned: {
    question: 'Are you sure you want to mark this item as returned to owner?',
    subtextMarking: 'Marking this item as returned will remove it from your list of managed items',
    buttonText: 'Mark as returned',
    buttonType: 'confirm'
  },
  soldItem: {
    question: 'Are you sure you want to mark this item as sold?',
    subtextMarking: 'Marking this item as sold will remove it from your list of managed items',
    buttonText: 'Mark as sold',
    buttonType: 'confirm'
  },
  emailVerify: {
    question: 'Email Verfication',
    subtextMarking: '',
    buttonText: 'Okay',
    buttonType: 'confirm',
    changeEmail: function(email) {
      this.subtextMarking = `A email verification email has been sent to: ${email}`;
    }
  },
  resetPassword: {
    question: 'Password Reset',
    subtextMarking: '',
    buttonText: 'Okay',
    buttonType: 'confirm',
    changeEmail: function(email) {
      this.subtextMarking = `A password reset email has been sent to: ${email}`;
    }
  },
  deleteItem: {
    question: 'Are you sure you want to delete this item/s?',
    subtextMarking: 'Deleting this item will remove it from your list of managed items/s.',
    buttonText: 'Delete Item/s',
    buttonType: 'hollowpink'
  },
  deleteAcc: {
    question: 'Are you sure you want to delete this account?',
    subtextMarking: 'Deleting this account will remove it and all associated items from the system.',
    buttonText: 'Delete Account',
    buttonType: 'hollowpink'
  },
  deleteUser: {
    question: 'Are you sure you want to delete this user?',
    subtextMarking: 'This will not remove any items they have registered from the system.',
    buttonText: 'Delete User',
    buttonType: 'hollowpink'
  },
  changeRole: {
    question: 'Are you sure you want to change this user role?',
    subtextMarking: 'This will change user role',
    buttonText: 'Change Role',
    buttonType: 'hollowpink'
  },
};

export const CONFIRM_TEXT_MESSAGE = {
  emailAddress: 'Email is updated',
  phoneNumber: 'Phone number is updated',
  locationDTO: 'Address is updated',
  businessName: 'Business Name is updated',
  workingHours: 'Working hours is updated',
  newBusinessAdded: 'Thanks for joining, we have sent you a verification email',
  Lost: 'Item marked as lost',
  preregistered: 'Item marked as preregistered',
  returned: 'Item marked as returned',
  Found: 'Item marked as found',
  publicName: 'User name is updated',
  userImg: 'User img is updated'
};

export const WORKING_DAYS_OPTIONS = [
  { label: 'M', value: 'MONDAY' },
  { label: 'T', value: 'TUESDAY' },
  { label: 'W', value: 'WEDNESDAY' },
  { label: 'T', value: 'THURSDAY' },
  { label: 'F', value: 'FRIDAY' },
  { label: 'S', value: 'SATURDAY' },
  { label: 'S', value: 'SUNDAY' },
];

export const DELIVERY_POPUP = {
  deliveryError: {
    title: 'Whoops, something went wrong',
    description: 'If you would still like to have your item delivered, click on the button below to be taken back to your notifications. ',
    buttonText: 'Back to notifications'
  },
  deliveryConfirmation: {
    title: 'Your item will soon be on it’s way back to you!',
    description: 'You will receive an email confirming your delivery. If your item is tracked the tracking number will be included.',
    buttonText: 'Back to my items'
  },
  matchConfirmed: {
    title: 'Yay! We found your item!',
    description: 'We will let the person who registered it know. You can now email them by clicking on the button below.',
    buttonText: 'Email Item Finder'
  },
  matchUnconfirmed: {
    title: 'Oh No! We’re sorry that wasn’t your item',
    description: 'But don’t worry we will keep searching for it!',
    buttonText: 'Back to my items'
  }
};

export const ITEM_SIZES = {
  S: 'Small < 1KG',
  M: 'Medium 1-4KG',
  L: 'Large 4-10KG',
  XL: 'Extra Large 10+KG'
};

export const SERVER_RESPONSE_POPUP_MESSAGE = {
  cancelledMatch: {
    404: 'Match with the match ID passed not found',
    200: 'Items matching cancelled'
  },
  confirmedMatch: {
    404: 'Match with the match ID passed not found',
    200: 'Items matching confirmed'
  },
  inviteNewUser: {
    200: 'Thanks for inviting a new user, we have sent a verification email to their email address',
    400: 'Request sent without email, or the user making the request does not belong to a business',
    404: 'User not found'
  },
};