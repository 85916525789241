import { put, takeEvery, call } from 'redux-saga/effects';
import { Business } from './../constants/actions';
import { push } from 'react-router-redux';
import * as Api from '../api/index';
import fetchWrapper from './fetchWrapper';

function* getUserLocation({ payload }) {
  const { postcode } = payload;
  yield put({ type: Business.GET_LOCATION_LIST_SAGA, payload: [] });
  if (postcode){
    const address = yield call(Api.getUserLocation, payload);
    yield put({ type: Business.GET_LOCATION_LIST_SAGA, payload: address });
  } else{
    yield put({ type: Business.GET_LOCATION_LIST_SAGA, payload: [] });
  }
}

function* getDataFromBusinessForm({ payload }) {
  yield put({ type: Business.GET_DATA_FROM_BUSINESS_FORM_SAGA, payload });
}

function* addNewBusiness({ payload }) {
  const response = yield fetchWrapper(call(Api.addNewBusiness, payload));
  if (response){
    yield put(push('/business-partners'));
    yield put({ type: Business.GET_LOCATION_LIST_SAGA, payload: [] });
    return null;
  }
  yield put(push('/add-new-business'));
}

export default function* components() {
  yield takeEvery(Business.GET_LOCATION_LIST, getUserLocation);
  yield takeEvery(Business.GET_DATA_FROM_BUSINESS_FORM, getDataFromBusinessForm);
  yield takeEvery(Business.ADD_NEW_BUSINESS, addNewBusiness);
};