export const COLORS = {
  base: 'rgba(0, 0, 0, 0.65)',
  primary: '#4A4A4A',
  // primaryActive: '',
  // primaryHover: '',
  secondary: '#FFFFFF',
  secondaryActive: '',
  secondaryHover: '',
  pink: '#E74E68',
  pinkLight: '#FCEDEF',
  shadow: '0 2px 6px 0 rgba(0,0,0,0.20)',
  grey: '#979797',
  lightGrey: '#EAEAEA',
  violet: '#6B40C3',
  violetLight: 'rgba(108, 65, 195, 0.2)',
  inputUnselected: '#E0E0E0',
  lightBlue: '#45E1CF',
  inputError: '#E74E68',
  inputFocused: '#9B9B9B',
  tableLost: '#FCEDEF',
  tableHover: '#F7F5FC',
  tableSelected: `#F7F5FC`,
  checked: '#45E1CF',
  header: '#8CDDF0',
  confirm: '#45E1CF',
  tags: '#349DC7',
  facebook: '#3b5998',
  facebook_lighter: '#3b5998',
  // light: 'grey',
  // disabled: '',
  // error: '',
  // errorActive: '',
  // errorHover: '',
  // success: '',
  // successActive: '',
  // successHover: '',
  // violet: 'linear-gradient(45deg, #5761b4 0%, #c142a0 100%)',
  // green: 'linear-gradient(-45deg, #7bbb5e 0%, #26b6e1 100%)',
  // border: '#f4f4f4',
};