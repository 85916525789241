import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import ls from 'local-storage';
import { Icon, Badge } from 'antd';

import { getUserNotifications } from '../../actions/user';
import { userNotificationCount } from '../../selectors/user';

class Notifications extends Component {
  
  state = {
    password: null,
    isSelectedLink: false
  };
  
  componentDidMount() {
    const { getUserNotifications, history : { location } } = this.props;
    const isSelectedLink = location && location.pathname.includes('notifications');
    const userUid = ls.get('CURRENT_USER_UID');
    getUserNotifications(userUid);
    this.setState({
      isSelectedLink: isSelectedLink
    })
  }
  
  componentWillReceiveProps(nextProps){
    const {  history : { location } } = nextProps;
    const isSelectedLink = location && location.pathname.includes('notifications');
    this.setState({
      isSelectedLink: isSelectedLink
    })
  }
  
  render() {
    const { userNotificationCount } = this.props;
    const { isSelectedLink } = this.state;

    const userUid = ls.get('CURRENT_USER_UID');
    const role = ls.get('ROLE');
    const showBadge = role !== 'FOUND_STAFF_MANAGEMENT'
      ? <Badge count={userNotificationCount} style={{
        minWidth: '15px',
        height: '15px',
        padding: '0 3px',
        fontSize: '11px',
        lineHeight: '15px'
      }}>
        <Link to={`/${userUid}/notifications`}>
          <Icon
            style={{ fontSize: '18px', color: 'white' }}
            type="bell"
            theme={isSelectedLink ? 'filled' : null}
          />
        </Link>
      </Badge> : null;
    return <div>{showBadge}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    userNotificationCount: userNotificationCount(state),
  };
};

const mapDispatchToProps = (dispatch, ...props) => {
  return {
    getUserNotifications: (userUid) => getUserNotifications(userUid)(dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
