import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';

const Logo = styled.div`
  font-size: 17pt;
  width: auto;
	//a{
   // background: -webkit-linear-gradient(#e33fde, #1890ff);
   // -webkit-background-clip: text;
   // -webkit-text-fill-color: transparent;
   // color: white;
	//	text-decoration: none;
	//}
	//@media (max-width: 899px) {
	//	position:absolute;
	//	left: 50%;
   //     transform: translateX(-50%);
	//}
	//@media (max-width: 576px) {
	//	display: none;
	//}
`;

export default ({ userUid }) => {
  return (
    <Logo>
      <Link to={`/${userUid}/profile`}>
        <img src={logo} alt="Logo"/>
      </Link>
    </Logo>
  );
}

