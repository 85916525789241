import { fromJS } from 'immutable';
import { User, Auth, Member, Components } from './../constants/actions';

const initState = fromJS({
  members: [],
  totalMembers: null,
  currentPage: null,
  membersItems: [],
  totalMembersItems: null,
});

const reducer = (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case User.GET_BUSINESS_MEMBERS_SAGA: {
      if (payload || payload === null) {
        const { items: members, totalElements, currentPage } = payload;
        return state.set('members', fromJS(members)).set('totalMembers', fromJS(totalElements)).set('currentPage', fromJS(currentPage));
      }
      return state;
    }
    case User.GET_BUSINESS_MEMBERS_ITEMS_SAGA: {
      if (payload || payload === null) {
        const { items: membersItems, totalElements: totalMembersItems, currentPage } = payload;
        return state.set('membersItems', fromJS(membersItems)).set('totalMembersItems', fromJS(totalMembersItems)).set('currentPage', fromJS(currentPage));
      }
      return state;
    }
    case Member.ON_CHANGE_MEMBER_ROLE_SAGA: {
      if (payload || payload === null) {
        const { userUid, roles } = payload;
        const indexToUpdate = state.get('members').findIndex(user => user.get('id') === userUid);
        return state.setIn(['members', indexToUpdate, 'roles'], fromJS(roles));
      }
      return state;
    }
    
    case Components.ON_UPDATE_ITEM_FIELD_SAGA: {
      if (payload || payload === null) {
        const { item, itemUid } = payload;
        const indexToUpdate = state.get('membersItems').findIndex(el => {
          return el.get('id') === itemUid;
        });
        item.id = itemUid;
        return state.setIn(['membersItems', indexToUpdate], fromJS(item));
      }
      return state;
    }
  
    case Auth.LOG_OUT: {
      return state.set('members', fromJS([]))
        .set('totalElements', null)
        .set('currentPage', null);
    }
  
    case Member.GET_BUSINESS_MEMBER_IMG_SAGA: {
      if (payload || payload === null) {
        const { userUid, img } = payload;
        const indexToUpdate = state.get('members').findIndex(member => member.get('id') === userUid);
        return state.setIn(['members', indexToUpdate, 'userProfilePicture'], fromJS(img));
      }
      return state;
    }
    
    default:
      return state;
  }
};

export default reducer;
