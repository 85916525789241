import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import user from './user';
import business from './business';
import auth from './auth';
import users from './users';
import components from './components';
import members from './members';
import admins from './admins';
import deliveries from './deliveries';

export default combineReducers({
  user,
  users,
  business,
  auth,
  components,
  members,
  admins,
  deliveries,
  routing: routerReducer
});