import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import asyncComponent from '../components/async';
import AuthRoute from './authRoute/AuthRoute';
import NonAuthRoute from './nonAuthRoute/NonAuthRoute';
import { Auth } from '../constants/actions';

const AsyncBusinessForm = asyncComponent(() => import ('../components/businessForm'));
const AsyncBusinessDetailsForm = asyncComponent(() => import ('../components/businessDetailsForm'));
const AsyncLogin = asyncComponent(() => import ('../pages/loginPage'));
const AsyncSignUp = asyncComponent(() => import ('./signUp'));
const AsyncBusinessUsers = asyncComponent(() => import ('../pages/businessUsers'));
const AsyncPublicUsers = asyncComponent(() => import ('../pages/publicUsers'));
const AsyncMembers = asyncComponent(() => import ('../pages/foundUsers'));
const AsyncAllItems = asyncComponent(() => import ('../pages/allItems'));
const AsyncBusinessUser = asyncComponent(() => import ('../pages/businessUser/BusinessUser'));
const AsyncPublicUser = asyncComponent(() => import ('../pages/publicUser/PublicUser'));
const AsyncFoundMember = asyncComponent(() => import ('../pages/profilePage/FoundMemberProfile'));
const AsyncProfile = asyncComponent(() => import ('./userProfile'));
const AsyncAddHoursForm = asyncComponent(() => import ('../pages/addHours'));
const AsyncCompanyMembers = asyncComponent(() => import ('../pages/companyMembers'));
const AsyncBusinessMembersProfile = asyncComponent(() => import ('../pages/profilePage/BusinessMemberProfile'));
const AsyncCompanyMemberProfile = asyncComponent(() => import ('../pages/profilePage/CompanyMemberProfile'));
const AsyncChangePassword = asyncComponent(() => import ('../pages/changePassword'));
const AsyncNotFoundPage = asyncComponent(() => import ('../pages/notFoundPage'));
const AsyncFoundItemPopup = asyncComponent(() => import ('../components/popups/foundItemPopup'));
const AsyncMatchItemsPage = asyncComponent(() => import ('../pages/matchItems'));
const AsyncNotificationPage = asyncComponent(() => import ('../pages/notificationPage'));
// DELIVERY FLOW WITH PAGES
// const AsyncCompareItemsPage = asyncComponent(() => import ('../pages/deliveryFlow/comparePage'));
// const AsyncBusinessInfoPage = asyncComponent(() => import ('../pages/deliveryFlow/businessInfoPage'));
// const AsyncPickItemPage = asyncComponent(() => import ('../pages/deliveryFlow/1pickItemPage'));
// const AsyncPersonalDetailsPage = asyncComponent(() => import ('../pages/deliveryFlow/personalDetails'));
// const AsyncDeliveryDetailsFormPage = asyncComponent(() => import ('../pages/deliveryFlow/deliveryFlow/deliveryDetails'));
// const AsyncDeliveryOptionsPage = asyncComponent(() => import ('../pages/deliveryFlow/2deliveryOptionsPage'));
// const AsyncDeliverySummaryPage = asyncComponent(() => import ('../pages/deliveryFlow/3deliverySummaryPage'));


const Routes = ({ location }) => {
  return (
    <Switch location={location}>
      <NonAuthRoute exact path="/" component={AsyncLogin}/>
      <NonAuthRoute path="/signup" component={AsyncSignUp}/>
      <AuthRoute exact path="/all-items" component={AsyncAllItems}/>
      <AuthRoute exact path="/all-items/:id" component={AsyncMatchItemsPage}/>
      <AuthRoute exact path="/add-new-business" component={AsyncBusinessForm}/>
      <AuthRoute path="/change-password" component={AsyncChangePassword}/>
      <AuthRoute exact path="/business-details" component={AsyncBusinessDetailsForm}/>
      <AuthRoute exact path="/edit-hours" component={AsyncAddHoursForm}/>
      <AuthRoute exact path="/business-partners" component={AsyncBusinessUsers}/>
      <AuthRoute exact path="/public-users" component={AsyncPublicUsers}/>
      <AuthRoute exact path="/found-members" component={AsyncMembers}/>
      <AuthRoute exact path="/:id/profile" component={AsyncProfile}/>
      <AuthRoute exact path="/:id/manage-members" component={AsyncCompanyMembers}/>
      <AuthRoute exact path="/:id/manage-members/:id" component={AsyncCompanyMemberProfile}/>
      <AuthRoute exact path="/:id/business-members" component={AsyncCompanyMembers}/>
      <AuthRoute exact path="/:id/business-members/:id" component={AsyncBusinessMembersProfile}/>
      
      <AuthRoute exact path="/:id/notifications" component={AsyncNotificationPage}/>
      {/*DELIVERY FLOW WITH PAGES*/}
      {/*<AuthRoute exact path="/:id/compare-items" component={AsyncCompareItemsPage}/>*/}
      {/*<AuthRoute exact path="/:id/business-info-page" component={AsyncBusinessInfoPage}/>*/}
      {/*<AuthRoute exact path="/:id/pick-item-size" component={AsyncPickItemPage}/>*/}
      {/*<AuthRoute exact path="/:id/personal-details" component={AsyncPersonalDetailsPage}/>*/}
      {/*<AuthRoute exact path="/:id/delivery-address" component={AsyncDeliveryDetailsFormPage}/>*/}
      {/*<AuthRoute exact path="/:id/delivery-options" component={AsyncDeliveryOptionsPage}/>*/}
      {/*<AuthRoute exact path="/:id/delivery-summary" component={AsyncDeliverySummaryPage}/>*/}
  
      <AuthRoute exact path="/:id/found-item" component={AsyncFoundItemPopup}/>
      <AuthRoute path="/public-users/:id" component={AsyncPublicUser}/>
      <AuthRoute path="/business-partners/:id" component={AsyncBusinessUser}/>
      <AuthRoute path="/found-members/:id" component={AsyncFoundMember}/>
      <Route component={AsyncNotFoundPage}/>
    </Switch>
  );
};

export default Routes;