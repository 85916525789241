import * as a from './../constants/actions';

export const sendInvite = (values) => {
  return (dispatch) => dispatch({
    type: a.User.SEND_INVITE,
    payload: values
  });
};

export const getUserInfo = (data) => {
  return (dispatch) => dispatch({
    type: a.User.GET_USER_INFO,
    payload: { ...data }
  });
};

export const resetUserPassword = ({ emailAddress, appType }) => {
  return (dispatch) => dispatch({
    type: a.User.RESET_USER_PASSWORD,
    payload: { emailAddress, appType }
  });
};

export const onUserActivateAccount = ({ emailAddress, appType, uid }) => {
  return (dispatch) => dispatch({
    type: a.User.USER_ACTIVATE_WITH_EMAIL,
    payload: { emailAddress, appType, uid }
  });
};

// export const getFoundItems = (userUid) => {
//   return (dispatch) => dispatch({
//     type: a.User.GET_USER_ITEMS_FOUND,
//     payload: { ...userUid }
//   });
// };

// export const getLostItems = (userUid) => {
//   return (dispatch) => dispatch({
//     type: a.User.GET_USER_ITEMS_LOST,
//     payload: { ...userUid }
//   });
// };

// export const getPreregisteredItems = (userUid) => {
//   return (dispatch) => dispatch({
//     type: a.User.GET_USER_ITEMS_PREREGISTERED,
//     payload: { ...userUid }
//   });
// };

export const onChangeUserItemStatus = (values) => {
  return (dispatch) => dispatch({
    type: a.User.ON_CHANGE_ITEM_BY_NEW_STATUS,
    payload: { ...values }
  });
};

export const onDeleteAccount = (values) => {
  return (dispatch) => dispatch({
    type: a.User.ON_DELETE_USER_ACCOUNT,
    payload: values
  });
};

export const onDeleteUser = (userUid) => {
  return (dispatch) => dispatch({
    type: a.User.ON_DELETE_USER,
    payload: userUid
  });
};


export const onChangeUserInfo = (value, fieldType, user) => {
  return (dispatch) => dispatch({
    type: a.User.ON_CHANGE_USER_INFO,
    payload: { value, fieldType, user }
  });
};

export const getUsersBySearch = (params) => {
  return (dispatch) => dispatch({
    type: a.User.GET_USERS_BY_SEARCH,
    payload: params
  });
};

export const getAllUserItemsList = (data) => {
  return (dispatch) => dispatch({
    type: a.User.GET_ALL_USER_ITEMS,
    payload: { ...data }
  });
};

export const onDeleteUserItems = (values, accountType) => {
  return (dispatch) => dispatch({
    type: a.User.ON_DELETE_ITEMS,
    payload: { ...values, accountType }
  });
};

export const getBusinessMembers = (data) => {
  return (dispatch) => dispatch({
    type: a.User.GET_BUSINESS_MEMBERS,
    payload: { ...data }
  });
};

export const getMembersItems = (data) => {
  return (dispatch) => dispatch({
    type: a.User.GET_BUSINESS_MEMBERS_ITEMS,
    payload: { ...data }
  });
};

export const onChangePassword = (oldPass, newPass) => {
  return (dispatch) => dispatch({
    type: a.User.ON_CREATE_NEW_PASSWORD,
    payload: { oldPass, newPass }
  });
};

export const onChangeUserRole = (role) => {
  return (dispatch) => dispatch({
    type: a.Member.ON_CHANGE_MEMBER_ROLE,
    payload: { ...role }
  });
};

export const getMembersBySearch = (params) => {
  return (dispatch) => dispatch({
    type: a.Member.GET_BUSINESS_MEMBERS_BY_SEARCH,
    payload: params
  });
};

export const onMarkItemAsReturned = (values) => {
  return (dispatch) => dispatch({
    type: a.Components.ON_MARK_ITEM_AS_RETURNED,
    payload: values
  });
};

export const onUploadUserImg = (file, userUid) => {
  return (dispatch) => dispatch({
    type: a.User.ON_UPLOAD_USER_IMG,
    payload: { file, userUid }
  });
};

export const getUsersList = (listData) => {
  return (dispatch) => dispatch({
    type: a.User.GET_USERS_LIST,
    payload: { ...listData }
  });
};

export const getBusinessUserItemsList = (data) => {
  return (dispatch) => dispatch({
    type: a.User.GET_BUSINESS_USER_ITEMS,
    payload: { ...data }
  });
};
export const getUserNotifications = (userUid) => {
  return (dispatch) => dispatch({
    type: a.User.GET_USER_NOTIFICATIONS,
    payload: { userUid }
  });
};
export const getUserLocations = (userUid) => {
  return (dispatch) => dispatch({
    type: a.User.GET_USER_LOCATIONS,
    payload: { userUid }
  });
};