import { message } from 'antd';
import ls from 'local-storage';
import axios from 'axios';
import _ from 'lodash';
import { STATUS_TYPES, CONFIRM_TEXT_MESSAGE, ITEM_TYPES, SERVER_RESPONSE_POPUP_MESSAGE } from '../constants/testData';

const API_URL_CURRENT = process.env.REACT_APP_API_URL;
let USER_UID_CURRENT = ls.get('CURRENT_USER_UID');
let ACCESS_TOKEN = ls.get('ACCESS_TOKEN');

/*
* Currently there are four roles -
* USER (public user),
* COMPANY (non-admin business user),
* COMPANY_ADMIN (business user),
* FOUND_STAFF_MANAGEMENT (super admin)
* */

const endpoints = {
  users: {
    getUsers(appType) {
      return appType === 'BUSINESS' ? 'api/v1/users/users/business' : 'api/v1/users/users/public';
    },
    getBusinessMembers(userUid) {
      return `api/v1/users/${userUid}/business/user`;
    },
    searchUsers(userUid) {
      return `api/v1/users/${userUid}/business/user`;
    },
    adminUsers: `api/v1/users/users/admin`,
  },
  locations: {
    postcode: 'api/v1/locations/addressByPostcode',
    addNewLocation(userUid) {
      return `api/v1/users/${userUid}/locations`;
    },
    getUserLocations(userUid) {
      return `api/v1/users/${userUid}/locations`;
    },
    getAddressById(addressUid) {
      return `api/v1/locations/${addressUid}`;
    },
  },
  itemController: {
    deleteItem: 'api/v1/items',
    getItems: 'api/v1/items',
    itemsStatus: 'api/v1/items/status',
    // allItems: 'api/v1/items/all',
    // markedFound(userUid) {
    //   return `api/v1/items/${userUid}/item`;
    // },
    // registerNewFoundItem(userUid) {
    //   return `api/v1/items/found/${userUid}`;
    // },
    // registerNewLostItem(userUid) {
    //   return `api/v1/items/lost/${userUid}`;
    // },
    // registerNewPreregisteredItem(userUid) {
    //   return `api/v1/items/preregistered/${userUid}`;
    // },
    // markedLost(userUid, itemUid) {
    //   return `api/v1/items/${userUid}/preregistered/${itemUid}/lost`;
    // },
    getBusinessItems(userUid) {
      return `api/v1/${userUid}/business/item`;
    },
    onUpdateItem(itemUid) {
      return `api/v1/items/${itemUid}`;
    },
    getItemImage(imgUid) {
      return `api/v1/images/${imgUid}`;
    },
    markItemAsReturned(itemUid) {
      return `api/v1/items/found/${itemUid}/returned`;
    },
    getItemById(itemUid) {
      return `api/v1/items/${itemUid}`;
    }
  },
  user: {
    resetPassword: `api/v1/users/password`,
    getUser(userUid) {
      return `api/v1/users/users/${userUid}`;
    },
    putNewUserInfo(userUid) {
      return `api/v1/users/${userUid}`;
    },
    deleteAccount(userUid) {
      return `api/v1/users/${userUid}`;
    },
    getUserAllItems(userUId) {
      return `api/v1/users/${userUId}/items/all`;
    },
    inviteNewUser(userUid) {
      return `api/v1/users/${userUid}/business/user`;
    },
    changePassword(userUid) {
      return `api/v1/users/password/${userUid}/change`;
    },
    changeRole(userUid) {
      return `/api/v1/users/${userUid}/roles`;
    },
    changeUserImg(userUid) {
      return `api/v1/users/image/${userUid}`;
    },
    getUserNotifications(userUid) {
      return `api/v1/users/${userUid}/notifications`;
    },
  },
  authentication: {
    resendEmailNew(userUid) {return `api/v1/users/${userUid}/verification`;},
    signIn: 'api/v1/authentication/tokens/regular',
    signUp: 'api/v1/users/regular',
    signInSocial: 'api/v1/authentication/tokens/social',
    signUpSocial: 'api/v1/users/social'
  },
  matches: {
    matchItems: 'api/v1/matches',
    cancelledMatch(matchUid) {
      return `api/v1/matches/${matchUid}/cancelled`;
    },
    confirmedMatch(matchUid) {
      return `api/v1/matches/${matchUid}/confirmed`;
    },
    getMatchItems(itemUid) {
      return `api/v1/matches/${itemUid}`;
    },
  },
  delivery: {
    getQuotes: 'api/v1/deliveries/quote',
    deliveries: 'api/v1/deliveries/delivery',
  },
  payments: {
    createPayment: 'api/v1/payments/',
    executePayment(paymentId) {
      return `api/v1/payments/${paymentId}/execute`;
    }
  }
};

axios.defaults.headers = {
  'Content-Type': 'application/json'
};

const transformRequestOptions = params => {
  let options = '';
  for (const key in params) {
    params[key].forEach(el => {
      options += `&${key}=${el}`;
    });
  }
  return options;
};
const errorMessage = (error, responseType) => {
  // const errorCode = error.response.status;
  // const errorDescription = error.response.data.message;
  // if (errorCode === 400) {
  //   message.error('Wrong password.');
  // } else {
  //   message.error(errorDescription);
  // }
  // return null;
  //
  const errorStatus = error.response.status;
  if (responseType) {
    errorStatus ? message.error(SERVER_RESPONSE_POPUP_MESSAGE[responseType][errorStatus]) : message.error('');
  } else {
    const errorDescription = error.response.data.message;
    errorStatus ? message.error(errorDescription) : message.error('');
  }
  return null;
};


// ----------- AUTH ----------- //

export async function signUp({ appType, email, password, businessName, publicName, businessType, workingHours, website = '', locationDTO, acceptMarketing }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.authentication.signUp}`;
    const businessUser = {
      appType,
      email,
      password,
      businessName,
      businessType,
      workingHours,
      locationDTO,
      'organization': true,
      website,
      acceptMarketing
    };
    const publicUser = {
      appType,
      email,
      password,
      name: publicName,
      acceptMarketing
    };
    const data = appType === 'BUSINESS' ? businessUser : publicUser;
    const mes = await axios.post(URL, data).then(response => response.data);
    message.success(mes.message);
    return true;
  } catch (error) {
    errorMessage(error);
  }
}

export async function logIn({ email, password, appType }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.authentication.signIn}`;
    const data = {
      'appType': appType,
      'email': email,
      'password': password
    };
    const response = await axios.post(URL, data).then(response => response.data);
    ls.set('ACCESS_TOKEN', response.token.value);
    ls.set('CURRENT_USER_UID', response.userUUID);
    ls.set('ROLE', response.role);
    ACCESS_TOKEN = response.token.value;
    USER_UID_CURRENT = response.userUUID;
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onLogInBySocials({ socialMediaToken, type, socialMediaSecret }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.authentication.signInSocial}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const data = {
      'appType': 'public',
      'socialMediaSecret': socialMediaSecret,
      'socialMediaToken': socialMediaToken,
      'type': type,
    };
    const response = await axios.post(URL, data, config).then(response => response.data);
    
    ls.set('ACCESS_TOKEN', response.token.value);
    ls.set('CURRENT_USER_UID', response.userUUID);
    ls.set('ROLE', response.role);
    ACCESS_TOKEN = response.token.value;
    USER_UID_CURRENT = response.userUUID;
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onSignUpBySocials({ socialMediaToken, type, socialMediaSecret }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.authentication.signUpSocial}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const data = {
      'appType': 'public',
      'socialMediaSecret': socialMediaSecret,
      'socialMediaToken': socialMediaToken,
      'type': type,
    };
    const response = await axios.post(URL, data, config).then(response => response.data);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function logOut() {
  ls.set('ACCESS_TOKEN', null);
  ls.set('ROLE', null);
  ls.set('CURRENT_USER_UID', null);
  // console.log('logOut');
  return true;
}

// ----------- AUTH ----------- //

// ----------- USERS ----------- //

export async function getUsersList({ page, pageSize, appType, role }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.users.getUsers(appType)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
      params: {
        page,
        pageSize,
        query: `roles:${role}`
      }
    };
    
    const response = await axios.get(URL, config).then(response => response.data);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function getMembersItems({ page, pageSize, datePeriodFilter, userUid }) {
  const dateQuery = datePeriodFilter ? datePeriodFilter : '';
  const status = 'status:2';
  try {
    const URL = `${API_URL_CURRENT}${endpoints.itemController.getBusinessItems(userUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
      params: {
        page,
        pageSize,
        query: `${dateQuery},${status}`
      }
    };
    const response = await axios.get(URL, config).then(response => response.data);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function getUsersBySearch({ searchFieldType, searchQuery, page, pageSize, appType, role }) {
  const search = searchQuery ? `${searchFieldType}:${searchQuery}` : null;
  const roles = `roles:${role}`;
  const query = `${search},${roles}`;
  try {
    const URL = `${API_URL_CURRENT}${endpoints.users.getUsers(appType)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
      params: {
        page: page,
        pageSize: pageSize,
        query: query
      }
    };
    const response = await axios.get(URL, config).then(response => response.data);
    
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function getBusinessMembers({ page, pageSize, userUid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.users.getBusinessMembers(userUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
      params: { page, pageSize }
    };
    const response = await axios.get(URL, config).then(response => response.data);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function getMembersBySearch({ searchQuery, page, pageSize, userUid }) {
  const query = searchQuery ? searchQuery : null;
  try {
    const URL = `${API_URL_CURRENT}${endpoints.users.searchUsers(userUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
      params: {
        page: page,
        pageSize: pageSize,
        query
      }
    };
    // console.log(config);
    const response = await axios.get(URL, config).then(response => response.data);
    
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function getAdminUsers({ page, pageSize }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.users.adminUsers}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
      params: {
        page: page,
        pageSize: pageSize
      }
    };
    
    const response = await axios.get(URL, config).then(response => response.data);
    // console.log(response);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

// ----------- USERS ----------- //


// ----------- USER ----------- //

export async function getUserInfo({ uid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.user.getUser(uid)}`;
    const headers = {
      Authorization: ACCESS_TOKEN
    };
    const user = await axios.get(URL, { headers }).then(response => response.data);
    return user;
  } catch (error) {
    errorMessage(error);
  }
}

export async function resetUserPassword({ emailAddress, appType }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.user.resetPassword}`;
    const data = {
      appType: appType,
      email: emailAddress
    };
    const password = await axios.post(URL, data).then(response => response.data);
    return password;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onChangeUserInfo({ value, fieldType, user }) {
  if (fieldType === 'publicName') {
    const [firstName, lastName] = value.trim().split(' ');
    value = { firstName, lastName };
  }
  try {
    const URL = `${API_URL_CURRENT}${endpoints.user.putNewUserInfo(user.id)}`;
    const config = { headers: { Authorization: ACCESS_TOKEN } };
    let data, updatedFields = null;
    
    if (_.isPlainObject(value)) {
      if (fieldType === 'publicName') {
        updatedFields = { ...value };
        data = { ...user, ...value };
      } else {
        updatedFields = { ...user[fieldType], ...value };
        data = { ...user, [fieldType]: updatedFields };
      }
    } else {
      updatedFields = value;
      data = { ...user, [fieldType]: value };
    }
    
    const req = await axios.put(URL, data, config).then(response => response.data);
    // console.log(req);
    message.success(CONFIRM_TEXT_MESSAGE[fieldType]);
    return updatedFields;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onUploadUserImg({ file, userUid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.user.changeUserImg(userUid)}`;
    const config = { headers: { Authorization: ACCESS_TOKEN, 'Content-Type': 'multipart/form-data' } };
    const data = new FormData();
    const img = new Blob([file]);
    data.append('file', img, img.filename);
    data.append('_method', 'PUT');
    const response = await axios.post(URL, data, config).then(response => response.status);
    message.success(CONFIRM_TEXT_MESSAGE['userImg']);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onDeleteAccount({ userUid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.user.deleteAccount(userUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const response = await axios.delete(URL, config).then(response => response);
    return true;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onSendInviteNewUser({ userUid, email }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.user.inviteNewUser(userUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const data = { email: email };
    const response = await axios.post(URL, data, config).then(response => response);
    message.success(SERVER_RESPONSE_POPUP_MESSAGE['inviteNewUser'][response.status]);
    return response.data;
    
  } catch (error) {
    if (error.response.status === 500) {
      errorMessage(error);
      return null;
    }
    errorMessage(error, 'inviteNewUser');
  }
}

export async function addNewBusiness({ email, password, businessName, businessType, workingHours, locationDTO, website = '', acceptMarketing }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.authentication.signUp}`;
    const data = {
      'appType': 'BUSINESS',
      email,
      password,
      businessName,
      businessType,
      workingHours,
      locationDTO,
      'organization': true,
      website,
      acceptMarketing
    };
    const response = await axios.post(URL, data).then(response => response.data);
    
    message.success(CONFIRM_TEXT_MESSAGE['newBusinessAdded']);
    return true;
  } catch (error) {
    errorMessage(error);
  }
}

export async function getUserAllItemsList({ userUid, page, pageSize, datePeriodFilter }) {
  const dateQuery = datePeriodFilter ? datePeriodFilter : '';
  try {
    const URL = `${API_URL_CURRENT}${endpoints.user.getUserAllItems(userUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
      params: {
        page, pageSize, query: dateQuery
      }
    };
    const response = await axios.get(URL, config).then(response => response.data);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function getUserLocation({ postcode }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.locations.postcode}`;
    const data = { postcode };
    const address = await axios.post(URL, data).then(response => response.data.address);
    // console.log(address);
    return address;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onUserActivateAccount({ emailAddress, appType, uid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.authentication.resendEmailNew(uid)}`;
    const data = {
      appType: appType,
      email: emailAddress,
    };
    const headers = { Authorization: ACCESS_TOKEN };
    const isActivate = await axios.post(URL, data, { headers }).then(response => response);
    // console.log(isActivate);
  } catch (error) {
    errorMessage(error);
  }
}

export async function onChangePassword({ oldPass, newPass }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.user.changePassword(USER_UID_CURRENT)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
    };
    const data = { oldPassword: oldPass, newPasswordConfirm: newPass, newPassword: newPass };
    const response = await axios.post(URL, data, config).then(response => response.data);
    message.success(response);
    return true;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onChangeUserRole({ roles, userUid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.user.changeRole(userUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const data = { roles };
    const response = await axios.put(URL, data, config).then(response => response.data);
    return true;
  } catch (error) {
    errorMessage(error);
  }
}

export async function getBusinessUserItemsList({ userUid, page, pageSize, datePeriodFilter }) {
  const dateQuery = datePeriodFilter ? datePeriodFilter : '';
  const status = 'status:2';
  try {
    const URL = `${API_URL_CURRENT}${endpoints.itemController.getBusinessItems(userUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
      params: {
        page,
        pageSize,
        query: `${dateQuery},${status}`
      }
    };
    const response = await axios.get(URL, config).then(response => response.data);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function getUserNotifications({ userUid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.user.getUserNotifications(userUid)}`;
    const headers = {
      Authorization: ACCESS_TOKEN
    };
    const response = await axios.get(URL, { headers }).then(response => response.data);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onGetUserLocations({ userUid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.locations.getUserLocations(userUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const locations = await axios.get(URL, config).then(response => response.data);
    return locations;
  } catch (error) {
    errorMessage(error);
  }
}

// ----------- USER ----------- //


// ----------- ITEMS ----------- //

export async function onGetItemsByFilter({ page, pageSize, appType, filterTitle, categoryTitle, registeredTitle, datePeriodFilter }) {
  const categoryQwery = categoryTitle && categoryTitle !== 'All' ? `category:${categoryTitle.toUpperCase()}` : '';
  const statusQwery = filterTitle && STATUS_TYPES[`${filterTitle.toLowerCase()}`] ? `status:${STATUS_TYPES[`${filterTitle.toLowerCase()}`]}` : '';
  const userQuery = registeredTitle && registeredTitle !== 'All' ? `appType:${registeredTitle.toUpperCase()}` : '';
  const dateQuery = datePeriodFilter ? datePeriodFilter : '';
  const queryParams = {
    userQuery,
    query: `${categoryQwery}, ${statusQwery}, ${dateQuery}`
  };
  
  try {
    const URL = `${API_URL_CURRENT}${endpoints.itemController.getItems}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
      params: {
        page: page,
        pageSize: pageSize,
        ...queryParams
      }
    };
    const response = await axios.get(URL, config).then(response => response.data);
    // console.log(response);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onChangeItemByStatus({ selectedItems, status, dateLost }) {
  const lostItemDetails = dateLost ? {
    'dateStart': dateLost.dateStart,
    'dateEnd': dateLost.dateEnd,
    'latitude': dateLost.lat,
    'longitude': dateLost.lng
  } : null;
  try {
    const statusUpdates = selectedItems.map(item => ({
      'status': status,
      'uuid': item.key,
      lostItemDetails
    }));
    const URL = `${API_URL_CURRENT}${endpoints.itemController.itemsStatus}`;
    const data = { 'statusUpdates': statusUpdates };
    const config = { headers: { Authorization: ACCESS_TOKEN } };
    // console.log(data);
    const response = await axios.post(URL, data, config).then(response => response.status);
    message.success(CONFIRM_TEXT_MESSAGE[ITEM_TYPES[status]]);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onDeleteItems({ ids }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.itemController.deleteItem}`;
    const config = {
      'params': { ids },
      'paramsSerializer': params => transformRequestOptions(params),
      headers: { Authorization: ACCESS_TOKEN }
    };
    
    const response = await axios.delete(URL, config).then(response => response);
    return !!response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onUpdateItemField({ updatedValues, pureItem }) {
  const { id, ...pureItemWithoutId } = pureItem;
  /*
  * pureItemWithoutId - this needs to be done since the server
  * returns an error if you transfer the updated item with id
  * */
  try {
    const URL = `${API_URL_CURRENT}${endpoints.itemController.onUpdateItem(id)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
    };
    const data = {
      ...pureItemWithoutId,
      ...updatedValues
    };
    const response = await axios.put(URL, data, config).then(response => response.data);
    // console.log(response);
    return data;
  } catch (error) {
    errorMessage(error);
  }
}

export async function getItemImage({ imgUid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.itemController.getItemImage(imgUid)}`;
    const config = { headers: { Authorization: ACCESS_TOKEN }, responseType: 'arraybuffer' };
    
    const imageEncode = (arrayBuffer, content_type) => {
      let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), (p, c) => {return p + String.fromCharCode(c);}, ''));
      return b64encoded ? `data:${content_type};base64,${b64encoded}` : null;
    };
    
    const b64EncodedImageString = await axios.get(URL, config).then(response => imageEncode(response.data, response.headers['content-type']));
    return b64EncodedImageString;
  } catch (error) {
    errorMessage(error);
  }
}

export async function markItemAsReturned({ ids }) {
  let itemUid = ids[0];
  try {
    // const ACCESS_TOKEN = ls.get('ACCESS_TOKEN');
    const URL = `${API_URL_CURRENT}${endpoints.itemController.markItemAsReturned(itemUid)}`;
    const config = { headers: { Authorization: ACCESS_TOKEN } };
    const response = await axios.put(URL, null, config).then(response => response.data);
    message.success(CONFIRM_TEXT_MESSAGE['returned']);
    // console.log(response);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onMatchItems({ lostItemUid, foundItemUid }) {
  //матчинг айтемов
  try {
    const URL = `${API_URL_CURRENT}${endpoints.matches.matchItems}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
      params: {
        'lost-uuid': lostItemUid,
        'found-uuid': foundItemUid
      }
    };
    const response = await axios.post(URL, null, config).then(response => response);
    // console.log('onMatchItems', response);
    return true;
  } catch (error) {
    errorMessage(error);
  }
}

export async function getItemById({ itemUid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.itemController.getItemById(itemUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
    };
    const response = await axios.get(URL, config).then(response => response.data);
    return response;
  } catch (error) {
    // console.log(error.response);
    errorMessage(error);
  }
}

export async function onCancelledMatchingItems({ matchUid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.matches.cancelledMatch(matchUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
    };
    const response = await axios.put(URL, null, config).then(response => response);
    message.success(SERVER_RESPONSE_POPUP_MESSAGE['cancelledMatch'][response.status]);
    return response;
  } catch (error) {
    errorMessage(error, 'cancelledMatch');
  }
}

export async function onConfirmMatchingItems({ matchUid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.matches.confirmedMatch(matchUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN },
    };
    const response = await axios.put(URL, null, config).then(response => response);
    message.success(SERVER_RESPONSE_POPUP_MESSAGE['confirmedMatch'][response.status]);
    return true;
  } catch (error) {
    // console.log(error.response);
    errorMessage(error, 'confirmedMatch');
  }
}

export async function onGetMatchItems({ matchUid }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.matches.getMatchItems(matchUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const response = await axios.get(URL, config).then(response => response.data);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

// ----------- ITEMS ----------- //

// ----------- DELIVERIES ----------- //
export async function getAddressById(addressUid) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.locations.getAddressById(addressUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    
    const response = await axios.get(URL, config).then(response => response.data);
    // console.log(response);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onAddNewLocation({ userUid, address }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.locations.addNewLocation(userUid)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const data = {
      ...address
    };
    const location = await axios.post(URL, data, config).then(response => response.data);
    return location.id;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onGetDeliveryQuotes({ deliveryLocation, itemId, packageSize, partnerId, pickupLocation }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.delivery.getQuotes}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const data = {
      deliveryLocation,
      itemId,
      packageSize,
      partnerId,
      pickupLocation
    };
    const response = await axios.post(URL, data, config).then(response => response.data.quotes);
    
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onAddSuccessDelivery({ paymentId, quoteId }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.delivery.deliveries}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const data = {
      paymentId,
      quoteId
    };
    const response = await axios.post(URL, data, config).then(response => response.data);
    
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

// ----------- DELIVERIES ----------- //

// ----------- PAYMENTS ----------- //

export async function onAddApprovedPayment(paymentId) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.payments.executePayment(paymentId)}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const response = await axios.post(URL, null, config).then(response => response.data);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

export async function onCreatePayment({ currency, tax }) {
  try {
    const URL = `${API_URL_CURRENT}${endpoints.payments.createPayment}`;
    const config = {
      headers: { Authorization: ACCESS_TOKEN }
    };
    const data = {
      'currency': currency,
      'provider': 'PAYPAL',
      'shipping': 0,
      'subTotal': 0,
      'tax': tax
    };
    const response = await axios.post(URL, data, config).then(response => response.data);
    // console.log(response);
    return response;
  } catch (error) {
    errorMessage(error);
  }
}

// ----------- PAYMENTS ----------- //

