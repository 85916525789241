import * as a from './../constants/actions';

export const closePopup = (location) => {
  return (dispatch) => dispatch({
    type: a.Components.CLOSE_POPUP,
    payload: { location }
  });
};

export const openPopup = (values, popupType, action) => {
    return (dispatch) => dispatch({
      type: a.Components.OPEN_POPUP,
    payload: { values, popupType, action }
  });
};

export const onGetItemsByFilter = (values) => {
  return (dispatch) => dispatch({
    type: a.Components.GET_ITEMS_BY_FILTER,
    payload: values
  });
};

export const onChangeItemStatus = (values) => {
  return (dispatch) => dispatch({
    type: a.Components.ON_CHANGE_ITEM_BY_NEW_STATUS,
    payload: { ...values }
  });
};

export const clearSelectedItems = (isClear) => {
  return (dispatch) => dispatch({
    type: a.Components.CLEAR_SELECTED_ITEMS_SAGA,
    payload: isClear
  });
};
// QUEST оформление экшенов = передавать отдельно каждый параметр или передавать обьектом?
export const onDeleteItems = (values) => {
  return (dispatch) => dispatch({
    type: a.Components.DELETE_ITEM,
    payload: values
  });
};

export const onUpdateItemField = (values) => {
  return (dispatch) => dispatch({
    type: a.Components.ON_UPDATE_ITEM_FIELD,
    payload: values
  });
};

export const getImage = (imgUid) => {
  return (dispatch) => dispatch({
    type: a.Components.GET_IMAGE_FOR_ITEM,
    payload: { imgUid }
  });
};

export const onGetMatchItems = (values) => {
  return (dispatch) => dispatch({
    type: a.Components.ON_GET_MATCHING_ITEMS,
    payload: values
  });
};

export const onMatchItems = (values) => {
  return (dispatch) => dispatch({
    type: a.Components.ON_MATCH_LOST_FOUND_ITEMS,
    payload: values
  });
};

export const getFoundImage = (foundItemUid) => {
  return (dispatch) => dispatch({
    type: a.Components.GET_FOUND_ITEM_IMG,
    payload: { imgUid: foundItemUid }
  });
};

export const getLostImage = (lostItemUid) => {
  return (dispatch) => dispatch({
    type: a.Components.GET_LOST_ITEM_IMG,
    payload: { imgUid: lostItemUid }
  });
};

export const getItemById = (itemUid) => {
  return (dispatch) => dispatch({
    type: a.Components.GET_ITEM_BY_ID,
    payload: { itemUid }
  });
};

export const onCancelledMatchingItems = (values) => {
  return (dispatch) => dispatch({
    type: a.Components.ON_CANCELLED_MATCHING_ITEMS,
    payload: values
  });
};

export const onConfirmMatchingItems = (values) => {
  return (dispatch) => dispatch({
    type: a.Components.ON_CONFIRM_MATCHING_ITEMS,
    payload: values
  });
};

export const getItemsMatchingByMatchId = (matchUid) => {
  return (dispatch) => dispatch({
    type: a.Components.GET_ITEMS_MATCHING_BY_MATCH_ID,
    payload: { matchUid }
  });
};

export const getAddressById = (addressUid) => {
  return (dispatch) => dispatch({
    type: a.Components.GET_ADDRESS_BY_ID,
    payload: addressUid
  });
};

export const addNewLocation = (userUid, address) => {
  return (dispatch) => dispatch({
    type: a.Components.ADD_NEW_LOCATION,
    payload: { userUid, address }
  });
};