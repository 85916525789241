import React, { Component } from 'react';
import Promise from 'bluebird';

Promise.config({
  cancellation: true
});

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      
      this.state = {
        component: null
      };
    }
    
    async componentDidMount() {
      this.componentPromise = new Promise((r) => importComponent().then(r));
      this.componentPromise.then(({ default: component }) => {
        this.setState({ component });
        this.componentPromise = null;
      });
    }
    
    componentWillUnmount() {
      if (this.componentPromise) {
        this.componentPromise.cancel();
      }
    }
    
    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : null;
    }
  }
  
  return AsyncComponent;
}