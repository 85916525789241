export const TEXT_SIZES = {
  xs: '12px',
  sm: '14px',
  base: '16px',
  lg: '18px',
  xl: '20px',
  xxl: '22px',
  xxxl: '30px',
  xxxxl: '36px',
  large: '42px'
};