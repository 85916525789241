import { put, takeEvery, call, select } from 'redux-saga/effects';
import { Components, Deliveries } from './../constants/actions';
import { replace } from 'react-router-redux';
import * as Api from '../api/index';
import fetchWrapper from './fetchWrapper';
import history from '../history';
import ls from 'local-storage';

function* onGetDeliveriesQuotes({ payload }) {
  let quotes = yield fetchWrapper(call(Api.onGetDeliveryQuotes, payload));
  if (quotes) {
    yield put({ type: Deliveries.GET_DELIVERIES_QUOTES_SAGA, payload: quotes });
  }
}

export function* onAddSuccessDelivery({ payload }) {
  let response = yield fetchWrapper(call(Api.onAddSuccessDelivery, payload));
  if (response) {
    // QUEST if you need a redirect to the match page
    // const matchId = yield select(state => { return getDeliveryMatchId(state)});
    // console.log('matchId', matchId);
    // console.log(history.location.pathname, 'history.location.pathname');
    // matchId 06f1f9b8-ff39-4f02-bd90-14e207692247
    // deliveries.js:22 /4eefb5eb-5e8a-429e-8593-ab9a311515c3/notifications history.location.pathname
    const userUid = ls.get('CURRENT_USER_UID');
    const popupAction = () => history.push(`/${userUid}/profile`);
    yield put(replace({ search: `popup=${Components.CONFIRM_DELIVERY_POPUP}`, params: {action: popupAction, popupType: 'deliveryConfirmation'}}));
  }
}

export default function* user() {
  yield takeEvery(Deliveries.GET_DELIVERIES_QUOTES, onGetDeliveriesQuotes);
};