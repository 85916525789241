import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '../../elements/styled/Elements';
import { closePopup } from '../../actions/components';
import { withRouter } from 'react-router-dom';

function PopupLayer({ closePopup, children, location }) {
  return (
    <Modal
      footer={null}
      wrapClassName="vertical-center-modal"
      visible={true}
      width={'auto'}
      onCancel={()=>closePopup(location)}
      centered
      transitionName='none'
      destroyOnClose={true}
    >
      {children}
    </Modal>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    closePopup: (location) => closePopup(location)(dispatch)
  };
};

export default withRouter(connect(null, mapDispatchToProps)(PopupLayer));