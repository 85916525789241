import React from 'react';
import { withRouter } from 'react-router-dom';

import * as c from '../../constants/actions';
import PopupLayer from '../../elements/popupLayer';

import asyncComponent from '../../components/async';

const getPopoup = (popup, props) => {
  switch (popup) {
    case c.Components.OPEN_POPUP:
      return asyncComponent(() => import('./PopupConfig'));
    case c.Components.CONFIRM_POPUP:
      return asyncComponent(() => import('./confirmPopup'));
    case c.Components.CONFIRM_DELIVERY_POPUP:
      return asyncComponent(() => import('./deliveryFlow/confirmDeliveriesPopup'));
    case c.Components.CONFIRM_POPUP_MARK_ITEM_AS_PENDING:
      return asyncComponent(() => import('./confirmPopup'));
    default:
      return null;
  }
};

class Popups extends React.Component {
  constructor(props) {
    super(props);
    const search = props.location && props.location.search;
    const params = search && new URLSearchParams(search);
    this.state = {
      params
    };
  }
  
  static getDerivedStateFromProps(props, state) {
    const search = props.location.search ;

    return {
      ...state,
      params: new URLSearchParams(search)
    };
  }
  
  render() {
    let { params } = this.state;
    // console.log(params);
    const popup = params && params.get('popup');
    const C = getPopoup(popup);
    return popup ? <PopupLayer>
      <C {...this.props}/>
    </PopupLayer> : null;
  }
}

export default withRouter(Popups);