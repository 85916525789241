import { createSelector } from 'reselect';
import { addNewLocation } from '../actions/components';
import tz from 'moment-timezone';
import moment from 'moment';

export const getUser = (state) => state.user;

export const getUserData = createSelector(
  [getUser],
  (data) => {
    return (data.get('user') !== null && data.get('user').toJS()) || data.get('user');
  }
);

export const isLoading = createSelector(
  [getUser],
  (data) => data.get('isLoading')
);

export const isResetting = createSelector(
  [getUser],
  (data) => data.get('password')
);

export const getItemsFound = createSelector(
  [getUser],
  (data) => {
    return data.get('foundItems').toJS();
  }
);

export const getItemsLost = createSelector(
  [getUser],
  (data) => {
    return data.get('lostItems').toJS();
  }
);

export const getItemsPreregistered = createSelector(
  [getUser],
  (data) => {
    return data.get('preregisteredItems').toJS();
  }
);

export const userItemsList = createSelector(
  [getUser],
  (data) => {
    return data.get('allUserItems').toJS();
  }
);

export const userTotalElements = createSelector(
  [getUser],
  (data) => data.get('totalElements')
);

export const getCurrentPage = createSelector(
  [getUser],
  (data) => data.get('currentPage')
);

export const userNotificationList = createSelector(
  [getUser],
  (data) => {
    return data.get('notifications').toJS();
  }
);

export const userNotificationCount = createSelector(
  [getUser],
  (data) => {
    const notifications = data.get('notifications').toJS();
    const endDay = moment().startOf('day');
    const notificationDisplayTime = +moment.tz(new Date(endDay).toUTCString(), 'Europe/London');
    const notificationShowing = notifications.filter(el => el.date >= notificationDisplayTime);
    return notificationShowing.length;
  }
);

const convertTextCase = (el) => {
  return Object.keys(el).map(key => {
    if (key === 'address1' || key === 'address2' || key === 'city') {
      return { [key]: el[key] && el[key].split(' ').map(el => {
        if (el) {
          return el[0] + el.toLowerCase().slice(1)
        }
        return null;
      }).join(' ')
    }
  }});
};

export const userLocationsList = createSelector(
  [getUser],
  (data) => {
    const locations = data.get('locations').toJS();
    if (locations.length) {
      const convertAddressList = [];
      locations.forEach(el => {
        if (el.id && el.id.length) {
          if (el.id.length > 15) {
            const address = convertTextCase(el);
            let text = {};
            address.forEach(el => {
              if (el !== undefined) {
                text = { ...text, ...el };
              }
            });
            convertAddressList.push({ ...el, ...text });
          }
        }
      });
      return convertAddressList;
    }
    return [];
  }
);



