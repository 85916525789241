import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import { replace, push } from 'react-router-redux';
import { Components } from './../constants/actions';
import * as Api from '../api/index';
import fetchWrapper from './fetchWrapper';
import history from '../history';


function* updateTableItems(response, payload) {
  const { total: totalElements, items, page: currentPage } = response;
  yield put(replace({ search: `page=${payload.page}`, params: payload.page }));
  yield put({ type: Components.GET_ITEMS_BY_FILTER_SAGA, payload: { items, totalElements, currentPage } });
  yield put({ type: Components.CLEAR_SELECTED_ITEMS_SAGA, payload: true });
}

export function* openPopup({ payload }) {
  if (payload.values && payload.values.location) {
    const params = new URLSearchParams(payload.values.location.search);
    const cleanParamsPopup = params && params.delete('popup');
    yield put(replace({ search: `?${params.toString()}&popup=${Components.OPEN_POPUP}`, params: payload }));
  }
}

export function* closePopup({ payload }) {
  const { location } = payload;
  if (location) {
    const params = new URLSearchParams(location.search);
    const cleanParamsPopup = params && params.delete('popup');
    yield put(replace({ search: `?${params.toString()}`, params: payload }));
  }
  yield put({ type: Components.GET_IMAGE_FOR_ITEM_SAGA, payload: null });
  yield put({ type: Components.GET_FOUND_ITEM_IMG_SAGA, payload: null });
  yield put({ type: Components.GET_LOST_ITEM_IMG_SAGA, payload: null });
}

function* onChangeItemStatus({ payload }) {
  let responseStatusCode = yield call(Api.onChangeItemByStatus, payload);
  if (responseStatusCode === 200) {
    let response = yield fetchWrapper(call(Api.onGetItemsByFilter, payload));
    if (response) {
      yield updateTableItems(response, payload);
    }
  }
}

function* onDeleteItems({ payload }) {
  let isDeleted = yield call(Api.onDeleteItems, payload);
  if (isDeleted) {
    let response = yield fetchWrapper(call(Api.onGetItemsByFilter, payload));
    if (response) {
      yield updateTableItems(response, payload);
    }
  }
}

function* onGetItemsByFilter({ payload }) {
  let response = yield fetchWrapper(call(Api.onGetItemsByFilter, payload));
  if (response) {
    yield updateTableItems(response, payload);
  }
}

function* getImageItem({ payload }) {
  if (payload.imgUid) {
    let img = yield fetchWrapper(call(Api.getItemImage, payload), 'FETCHING_IMG');
    if (img) {
      yield put({ type: Components.GET_IMAGE_FOR_ITEM_SAGA, payload: img });
    }
  }
}

function* onUpdateItemField({ payload }) {
  let response = yield fetchWrapper(call(Api.onUpdateItemField, payload));
  if (response) {
    const itemUid = payload && payload.pureItem && payload.pureItem.id;
    const item = response;
    yield put({ type: Components.ON_UPDATE_ITEM_FIELD_SAGA, payload: { itemUid, item } });
  }
}

function* onGetMatchItems({ payload }) {
  // TODO обавить проверку для респонса
  let response = yield fetchWrapper(call(Api.onGetMatchItems, payload));
  
  // if (response) {
  //   yield updateTableItems(response, payload);
  // }
}

function* onMatchItems({ payload }) {
  // TODO сохранять страницу алл айтемс с которой перешел на матчинг
  const { location, lostItemUid, foundItemUid, page, pageSize, popupType } = payload;
  let response = yield fetchWrapper(call(Api.onMatchItems, { lostItemUid, foundItemUid }));
  
  if (response) {
    yield closePopup({ payload: { location } });
    yield openPopup({ payload: { values: { location, status: 'pending' }, popupType } });
    yield delay(2000);
    yield closePopup({ payload: { location } });
    yield put(push('/all-items'));
    return;
  }
  yield put(push('/all-items'));
}

function* getFoundImage({ payload }) {
  if (payload.imgUid) {
    let img = yield fetchWrapper(call(Api.getItemImage, payload), 'FETCHING_FOUND_ITEM_IMG');
    if (img) {
      yield put({ type: Components.GET_FOUND_ITEM_IMG_SAGA, payload: img });
    }
  }
}

function* getLostImage({ payload }) {
  if (payload.imgUid) {
    let img = yield fetchWrapper(call(Api.getItemImage, payload), 'FETCHING_LOST_ITEM_IMG');
    if (img) {
      yield put({ type: Components.GET_LOST_ITEM_IMG_SAGA, payload: img });
    }
  }
}

function* onCancelledMatchingItems({ payload }) {
  const { matchUid, location, userUid, popupType, action } = payload;
  const response = yield call(Api.onCancelledMatchingItems, { matchUid });
  if (response) {
    yield openPopup({ payload: { values: { location, userUid }, popupType: popupType, action: action } });
    return;
  }
  yield put(push(`/${userUid}/notifications`));
}

function* onConfirmMatchingItems({ payload }) {
  const { matchUid, location, userUid, popupType, action } = payload;
  const response = yield call(Api.onConfirmMatchingItems, { matchUid });
  if (response) {
    yield openPopup({ payload: { values: { location, userUid }, popupType: popupType, action: action } });
    return;
  }
  yield put(push(`/${userUid}/notifications`));
}

function* onGetItemById({ payload }, actionType) {
  const type = actionType ? Components[actionType] : Components.GET_LOST_ITEM_BY_ID;
  let response = yield fetchWrapper(call(Api.getItemById, payload));
  if (response) {
    yield put({ type, payload: response });
    return response;
  }
}

function* getItemsMatchingByMatchId({ payload }) {
  let response = yield fetchWrapper(call(Api.onGetMatchItems, payload));
  
  if (response) {
    let lostItem = yield onGetItemById({ payload: { itemUid: response.lostItemId } }, 'GET_LOST_ITEM_BY_ID');
    
    if (lostItem) {
      const lostItemImgUid = lostItem && lostItem.images && lostItem.images[0];
      const foundItemImgUid = response.foundItem && response.foundItem.images[0];
      if (lostItemImgUid) {
        yield getLostImage({ payload: { imgUid: lostItemImgUid } });
      } else {
        yield put({ type: Components.NOT_FOUND_LOST_ITEM });
      }
      if (foundItemImgUid) {
        yield getFoundImage({ payload: { imgUid: foundItemImgUid } });
      }
      yield put({ type: Components.GET_FOUND_ITEM_BY_ID, payload: response.foundItem });
      if (response.partnerInfo) {
        const uid = response.partnerInfo.id;
        let partner = yield fetchWrapper(call(Api.getUserInfo, { uid }));
        const partnerInfo = { ...response.partnerInfo, phoneNumber: partner.phoneNumber };
        yield put({ type: Components.GET_USER_INFO, payload: {} });
        yield put({ type: Components.GET_PARTNER_INFO, payload: partnerInfo });
      }
      if (response.userInfo) {
        yield put({ type: Components.GET_PARTNER_INFO, payload: {} });
        yield put({ type: Components.GET_USER_INFO, payload: response.userInfo });
      }
    } else {
      const params = history.location.pathname;
      yield put(push(params));
    }
  }
}

function* getAddressById({ payload }) {
  let response = yield fetchWrapper(call(Api.getAddressById, payload));
  if (response) {
    yield put({ type: Components.GET_ADDRESS_BY_ID_SAGA, payload: response });
  }
}

function* addNewLocation({ payload }) {
  let locationId = yield fetchWrapper(call(Api.onAddNewLocation, payload));
  if (locationId) {
    yield put({ type: Components.ADD_NEW_LOCATION_SAGA, payload: locationId });
  }
}


export default function* components() {
  yield takeEvery(Components.OPEN_POPUP, openPopup);
  yield takeEvery(Components.CLOSE_POPUP, closePopup);
  yield takeEvery(Components.ON_CHANGE_ITEM_BY_NEW_STATUS, onChangeItemStatus);
  yield takeEvery(Components.DELETE_ITEM, onDeleteItems);
  yield takeEvery(Components.GET_ITEMS_BY_FILTER, onGetItemsByFilter);
  yield takeEvery(Components.ON_UPDATE_ITEM_FIELD, onUpdateItemField);
  yield takeEvery(Components.GET_IMAGE_FOR_ITEM, getImageItem);
  yield takeEvery(Components.ON_GET_MATCHING_ITEMS, onGetMatchItems);
  yield takeEvery(Components.ON_MATCH_LOST_FOUND_ITEMS, onMatchItems);
  yield takeEvery(Components.GET_FOUND_ITEM_IMG, getFoundImage);
  yield takeEvery(Components.GET_LOST_ITEM_IMG, getLostImage);
  yield takeEvery(Components.GET_ITEM_BY_ID, onGetItemById);
  yield takeEvery(Components.ON_CANCELLED_MATCHING_ITEMS, onCancelledMatchingItems);
  yield takeEvery(Components.ON_CONFIRM_MATCHING_ITEMS, onConfirmMatchingItems);
  yield takeEvery(Components.GET_ITEMS_MATCHING_BY_MATCH_ID, getItemsMatchingByMatchId);
  yield takeEvery(Components.GET_ADDRESS_BY_ID, getAddressById);
  yield takeEvery(Components.ADD_NEW_LOCATION, addNewLocation);
};