import { put } from 'redux-saga/effects';
import { Components } from './../constants/actions';

export default function* f(callback, actionType) {
  const type = actionType ? Components[actionType] : Components.FETCHING_SAGA;
  yield put({ type, payload: true });
  try {
    let r = yield callback;
    yield put({ type, payload: false });
    return r;
  } catch (e) {
    yield put({ type, payload: false });
    return null;
  }
}