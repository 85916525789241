import React, { Component } from 'react';
import { Menu, Dropdown, Icon, message, Badge } from 'antd';
import { NavLink as Nav, withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ls from 'local-storage';

import { getToken } from '../../selectors/auth';
import { logOut } from '../../actions/auth';
import { COLORS } from '../../constants/colors';
import { TEXT_SIZES } from '../../constants/textSizes';
import Notifications from '../../components/notifications';


const NavLink = styled(Nav)`
	text-decoration: none;
`;
const Settings = styled.div`
  position: relative;
	display: flex;
	align-items: center;
	width: auto;
  font-size: ${TEXT_SIZES.base};
  color: ${COLORS.secondary};
  margin-left: 20px;
  line-height: 18px;
  &.active{
    &:before{
      left:0;
      width:100%;
    }
	}
	&:before{
    content:'';
    position:absolute;
    bottom:2px;
    right:50%;
    border-bottom:3px solid white;
    transition:all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    width:0;
    left:50%;
  }
	>div {
		&:before, &:after {
			content: '';
			position: absolute;
			top: 50%;
			right: -16px;
			width: 8px;
			vertical-align: baseline;
			background: #fff;
			height: 1.5px;
			border-radius: 2px;
			transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
		&:before {
		  transform: rotate(-45deg) translateX(3px);
		}
		&:after {
			transform: rotate(45deg) translateX(-3px);
		}
		&:hover {
			&:before{
				transform: rotate(45deg) translateX(3px);
			}
			&:after{
				transform: rotate(-45deg) translateX(-3px);
			}
		}
	}
	
	&:before{
		content:'';
		position:absolute;
		left:50%;
		bottom:2px;
		right:50%;
		width:0;
		border-bottom:3px solid white;
		transition:all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	}
  &:hover{
    font-size: ${TEXT_SIZES.base};
		text-shadow:0 0 1px ${COLORS.secondary};
	  &:before{
      width:100%;
      left:0;
    }
  }
`;

const menu = (logOut, role, email) => {
  const userUid = ls.get('CURRENT_USER_UID');
  let menuItems = [];
  switch (role) {
    case 'FOUND_STAFF_MANAGEMENT':
      menuItems = ['Profile', 'Sign Out'];
      break;
    case 'COMPANY_ADMIN':
      menuItems = ['Profile', 'Manage Members', 'Contact', 'Sign Out'];
      break;
    case 'COMPANY':
      menuItems = ['Profile', 'Contact', 'Sign Out'];
      break;
    case 'USER':
      menuItems = ['Profile', 'Contact', 'Sign Out'];
      break;
    default:
      break;
  }
  
  return <Menu>{
    menuItems.map((item, key) => {
      let menuLink = item.toLowerCase().split(' ').join('-');
      if (menuLink === 'sign-out') {
        return <Menu.Item key={key}>
          <NavLink
            replace={false}
            to='/'
            onClick={logOut}
          >
            {item}</NavLink>
        </Menu.Item>;
      } else if (menuLink === 'contact') {
        return <Menu.Item key={key}>
          <a
            href={`mailto:support@found.cloud`}
            data-rel="external">{item}</a>
        </Menu.Item>;
      }
      else {
        return (
          <Menu.Item key={key}>
            <NavLink
              replace={false}
              to={menuLink !== 'sign-out' ? `/${userUid}/${menuLink}?page=1` : '/'}
            >
              {item}
            </NavLink>
          </Menu.Item>
        );
      }
    })}
  </Menu>;
};

class DropdownMenu extends Component {
  state = {
    isActive: false,
    path: ''
  };
  
  componentDidMount() {
    const { location: { pathname } } = this.props;
    const path = `${pathname.split('/')[pathname.split('/').length - 1]}`;
    if (path === 'profile' || path === 'manage-members') {
      this.setState({ isActive: true, path });
    }
  }
  
  componentWillReceiveProps(nextProps) {
    const { location: { pathname } } = nextProps;
    const path = `${pathname.split('/')[pathname.split('/').length - 1]}`;
    if (path === 'profile' || path === 'manage-members') {
      this.setState({ isActive: true, path });
    } else {
      this.setState({ path, isActive: false });
    }
  }
  
  render() {
    const { logOut, role, email } = this.props;
    return (
      <React.Fragment>
        <Notifications/>
        <Dropdown overlay={menu(logOut, role, email)}>
          <Settings className={`ant-dropdown-link ${this.state.isActive ? 'active' : ''}`}>
            <div>Settings</div>
          </Settings>
        </Dropdown>
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: getToken(state)
  };
};

const mapDispatchToProps = (dispatch, ...props) => {
  return {
    logOut: () => logOut()(dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DropdownMenu));