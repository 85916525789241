import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import ls from 'local-storage';

class AuthRoute extends Component {
  render() {
    const { component: Component, ...rest } = this.props;
    const auth = ls.get('ACCESS_TOKEN');
    const role = ls.get('ROLE');
    return (
      <Route {...rest} render={props => {
        if (auth) {
          return <Component {...props} auth={auth} role={role}/>;
        }
        return <Redirect to={{
          pathname: '/'
        }}/>;
      }
      }/>
    );
  }
}

export default AuthRoute;
