import { put, takeEvery, call, fork } from 'redux-saga/effects';
import { User, Components, Auth, Member } from './../constants/actions';
import { replace } from 'react-router-redux';
import * as Api from '../api/index';
import fetchWrapper from './fetchWrapper';


function* updateTableItems(response, actionType) {
  const { total: totalElements, items, page: currentPage } = response;
  for (let item of items) {
    yield fork(getUserImg, item, 'GET_USER_IMG_SAGA');
  }
  yield put(replace({ search: `usersPage=${currentPage}`, params: currentPage }));
  yield put({ type: User[actionType], payload: { items, totalElements, currentPage } });
  yield put({ type: Components.CLEAR_SELECTED_ITEMS_SAGA, payload: true });
}

export function* getUserImg(user, actionType) {
  if (user && user.userProfilePicture) {
    const av = user.userProfilePicture && user.userProfilePicture.split('/');
    const imgUid = av[av.length - 1];
    const img = yield fetchWrapper(call(Api.getItemImage, { imgUid }), 'FETCHING_IMG');
    if (img) {
      yield put({ type: User[actionType], payload: { img, userUid: user.id } });
    }
  }
}

function* getUsersBySearch({ payload }) {
  let response = yield fetchWrapper(call(Api.getUsersBySearch, payload));
  if (response) {
    yield updateTableItems(response, 'GET_USERS_LIST_SAGA');
  }
}

function* getUsersList({ payload }) {
  let response = yield fetchWrapper(call(Api.getUsersList, payload));
  if (response) {
    yield updateTableItems(response, 'GET_USERS_LIST_SAGA');
  }
}

export default function* user() {
  yield takeEvery(User.GET_USERS_BY_SEARCH, getUsersBySearch);
  yield takeEvery(User.GET_USERS_LIST, getUsersList);
};