import { fromJS, Seq } from 'immutable';
import { User, Auth, Components } from './../constants/actions';

const initState = fromJS({
  user: null,
  isResetting: null,
  foundItems: [],
  lostItems: [],
  preregisteredItems: [],
  allUserItems: [],
  totalElements: null,
  currentPage: null,
  notifications: [],
  locations: []
});

const reducer = (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case User.GET_USER_ITEMS_PREREGISTERED_SAGA: {
      if (payload || payload === null) {
        return state.set('preregisteredItems', fromJS(payload));
      }
      return state;
    }
    case User.GET_USER_ITEMS_LOST_SAGA: {
      if (payload || payload === null) {
        return state.set('lostItems', fromJS(payload));
      }
      return state;
    }
    case User.GET_USER_ITEMS_FOUND_SAGA: {
      if (payload || payload === null) {
        return state.set('foundItems', fromJS(payload));
      }
      return state;
    }
    case User.RESET_USER_PASSWORD_SAGA: {
      if (payload || payload === null) {
        return state.set('isResetting', fromJS(payload));
      }
      return state;
    }
    case User.GET_USER_INFO_SAGA: {
      if (payload || payload === null) {
        return state.set('user', fromJS(payload));
      }
      return state;
    }
    case User.ON_CHANGE_USER_INFO_SAGA: {
      if (payload || payload === null) {
        const { value, fieldType } = payload;
        return state.setIn(['user', fieldType], fromJS(value));
      }
      return state;
    }
    case User.GET_ALL_USER_ITEMS_SAGA: {
      if (payload || payload === null) {
        const { items: allUserItems, totalElements, currentPage } = payload;
        return state.set('allUserItems', fromJS(allUserItems)).set('totalElements', fromJS(totalElements)).set('currentPage', fromJS(currentPage));
      }
      return state;
    }
    
    case User.GET_BUSINESS_USER_ITEMS_SAGA: {
      if (payload || payload === null) {
        const { items: allUserItems, totalElements, currentPage } = payload;
        return state.set('allUserItems', fromJS(allUserItems)).set('totalElements', fromJS(totalElements)).set('currentPage', fromJS(currentPage));
      }
      return state;
    }
    
    case Components.ON_UPDATE_ITEM_FIELD_SAGA: {
      if (payload || payload === null) {
        const { item, itemUid } = payload;
        const indexToUpdate = state.get('allUserItems').findIndex(el => {
          return el.get('id') === itemUid;
        });
        item.id = itemUid;
        return state.setIn(['allUserItems', indexToUpdate], fromJS(item));
      }
      return state;
    }
    
    case User.GET_CURRENT_USER_IMG_SAGA: {
      if (payload || payload === null) {
        const { img } = payload;
        return state.setIn(['user', 'userProfilePicture'], fromJS(img));
      }
      return state;
    }
    
    case User.GET_USER_NOTIFICATIONS_SAGA: {
      if (payload || payload === null) {
        return state.set('notifications', fromJS(payload));
      }
      return state;
    }
    
    case User.GET_USER_LOCATIONS_SAGA: {
      if (payload || payload === null) {
        return state.set('locations', fromJS(payload));
      }
      return state;
    }
    
    
    case Auth.LOG_OUT: {
      return state.set('user', null)
        .set('isResetting', null)
        .set('foundItems', fromJS([]))
        .set('lostItems', fromJS([]))
        .set('preregisteredItems', fromJS([]))
        .set('allUserItems', fromJS([]))
        .set('totalElements', null)
        .set('currentPage', null)
        .set('notifications', fromJS([]))
        .set('locations', fromJS([]));
    }
    default:
      return state;
  }
};

export default reducer;
