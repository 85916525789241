import { createSelector } from 'reselect';

const getAuth = (state) => state.auth;

export const getToken = createSelector(
  [getAuth],
  (data) => data.get('token')
);

export const getCurrentUserUid = createSelector(
  [getAuth],
  (data) => data.get('currentUserUid')
);

export const currentAppType = createSelector(
  [getAuth],
  (data) => data.get('appType')
);