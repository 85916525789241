import { put, takeEvery, call, fork, delay } from 'redux-saga/effects';
import * as Api from '../api/index';
import { replace, push } from 'react-router-redux';

import fetchWrapper from './fetchWrapper';
import { openPopup, closePopup } from './components';
import { Member, User, Components } from './../constants/actions';


export function* updateTableItems(response, actionType) {
  const { total: totalElements, items, page: currentPage } = response;
  for (let item of items) {
    yield fork(getMemberImg, item, 'GET_BUSINESS_MEMBER_IMG_SAGA');
  }
  yield put(replace({ search: `usersPage=${currentPage}`, params: currentPage }));
  yield put({ type: User[actionType], payload: { items, totalElements, currentPage } });
}

function* onChangeUserRole({ payload }) {
  let response = yield fetchWrapper(call(Api.onChangeUserRole, payload));
  if (response) {
    yield put({ type: Member.ON_CHANGE_MEMBER_ROLE_SAGA, payload });
  }
}

function* getMembersBySearch({ payload }) {
  let response = yield fetchWrapper(call(Api.getMembersBySearch, payload));
  if (response) {
    yield updateTableItems(response, 'GET_BUSINESS_MEMBERS_SAGA');
  }
}

function* getBusinessMembers({ payload }) {
  let response = yield fetchWrapper(call(Api.getBusinessMembers, payload));
  if (response) {
    yield updateTableItems(response, 'GET_BUSINESS_MEMBERS_SAGA');
  }
}

function* getMemberImg(user, actionType) {
  if (user && user.userProfilePicture) {
    const av = user.userProfilePicture && user.userProfilePicture.split('/');
    const imgUid = av[av.length - 1];
    const img = yield fetchWrapper(call(Api.getItemImage, { imgUid }), 'FETCHING_IMG');
    if (img) {
      yield put({ type: Member[actionType], payload: { img, userUid: user.id } });
    }
  }
}

function* onDeleteMemberAccount({ payload }) {
  const { deletedUserUid, redirectTo } = payload;
  let response = yield fetchWrapper(call(Api.onDeleteAccount, { userUid: deletedUserUid }));
  if (response) {
    if (redirectTo) {
      yield put(push(redirectTo));
      return null;
    }
    yield getBusinessMembers({ payload });
  }
}

function* sendInvite({ payload }) {
  const { location, popupType, userUid, email, page, pageSize } = payload;
  let response = yield fetchWrapper(call(Api.onSendInviteNewUser, { userUid, email }));
  if (response) {
    yield closePopup({ payload: { location } });
    yield openPopup({ payload: { values: { location, page, pageSize, userUid }, popupType } });
    yield delay(2000);
    yield getBusinessMembers({ payload });
    return;
  }
  yield closePopup({ payload: { location } });
}

export default function* user() {
  yield takeEvery(Member.ON_CHANGE_MEMBER_ROLE, onChangeUserRole);
  yield takeEvery(Member.GET_BUSINESS_MEMBERS_BY_SEARCH, getMembersBySearch);
  yield takeEvery(User.GET_BUSINESS_MEMBERS, getBusinessMembers);
  yield takeEvery(Member.ON_DELETE_MEMBER_ACCOUNT, onDeleteMemberAccount);
  yield takeEvery(User.SEND_INVITE, sendInvite);
};