import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Spin, Icon } from 'antd';
import ls from 'local-storage';

import { getToken } from '../../selectors/auth';
import { isLoading } from '../../selectors/user';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin/>;


class NonAuthRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: props.isLoading
    };
  }
  
  componentWillReceiveProps(nextProps) {
    const { isLoading } = nextProps;
    this.setState({
      isLoading
    });
  }
  
  render() {
    const { component: Component,  ...rest } = this.props;
    const { isLoading } = this.state;
    const auth = ls.get('ACCESS_TOKEN');
    const currentUser = ls.get('CURRENT_USER_UID');
    return (
      <Route {...rest} render={props => {
        if (!auth) {
          return isLoading
            ? <Row
              type='flex'
              justify='center'
              align='middle'
              className='Spin'
            >
              <Spin indicator={antIcon}/>
            </Row>
            : <Component {...props}/>;
        }
        return <Redirect to={{
          pathname: `/${currentUser}/profile`
        }}/>;
      }
      }/>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: getToken(state),
    isLoading: isLoading(state)
  };
};

export default connect(mapStateToProps)(NonAuthRoute);