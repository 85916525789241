import React, { Component } from 'react';

import { Layout } from 'antd';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';


import history from './history';
import store from './store';
import Routes from './routes';
import Popups from './components/popups';
import './index.less';

import Flexbox from 'flexbox-react';
import AppHeader from './components/header';
import { ContextProvider } from './components/contextProvider';
import { Router } from 'react-router-dom';

class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      menuToggle: this.menuToggle,
    };
  }
  
  onAppClick = () => {
    this.setState({
      collapsed: true
    });
  };
  
  menuToggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };
  
  render() {
    return (
      <ContextProvider value={this.state}>
        <Layout className="Wrapper">
          <AppHeader/>
          <Flexbox className="Content" flexDirection="column" onClick={this.onAppClick}>
            <Routes/>
          </Flexbox>
          <Popups/>
        </Layout>
      </ContextProvider>
    );
  }
}

const ApplicationWrapper = () => (
  <Provider store={store}>
    <Router history={history}>
      <Application/>
    </Router>
  </Provider>
);

export default ApplicationWrapper;